export function getAllUrlParams() {

	const search = window.location?.search?.substring(1); 

	let result = {};

	try {
		if (search) {
			let searchParams = new URLSearchParams(search);
			result = Object.fromEntries([...searchParams]);
		}
	}
	catch (err) {
		console.error('Malformed url params:', err)
	}

	return result;

};