export { CandidateCoachingPlan };

function CandidateCoachingPlan({ plans }) {

    if (!plans) {
        return null;
    }

    return (
        <div id="candidate-coaching-plan">
                <h4 className="mt-5">Coaching Plan for the Next Level</h4>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Dimension</th>
                            <th scope="col">Plan</th>
                        </tr>
                    </thead>
                    <tbody>

                        {plans.map((row, idx) => {
                            return (
                                <tr key={"coaching-" + idx}>
                                    <th scope="row">{row.name}</th>
                                    <td>{row.plan}</td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
        </div>
    );

}