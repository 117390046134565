export const PM_LEVELS = {
  L3: {
    Google: "APM (Associate Product Manager)",
    Facebook: "RPM (IC3)",
    Microsoft: "60 (PM 1)",
    Amazon: "No hire",
    Tencent: "T4",
    Bytedance: "1-2",
    Apple: "ICT2 (Junior PM)"
  },
  L4: {
    Google: "L4 (Product Manager 1)",
    Facebook: "IC4 (Product Manager)",
    Microsoft: "62 (PM 2)",
    Amazon: "L5 (PM)",
    Tencent: "T7",
    Bytedance: "2-1",
    Apple: "ICT3 (PM)"
  },
  L5: {
    Google: "L5 (Product Manager 2)",
    Facebook: "IC5 (Product Manager)",
    Microsoft: "65 (Senior PM)",
    Amazon: "L6 (Senior PM)",
    Tencent: "T10",
    Bytedance: "2-2",
    Apple: "ICT4 (PM)"
  },
  L6: {
    Google: "L6 (Senior PM)",
    Facebook: "IC6 (Product Manager)",
    Microsoft: "66 (Principal PM)",
    Amazon: "L6 (Senior PM)",
    Tencent: "T13",
    Bytedance: "3-1",
    Apple: "ICT5"
  },
  L7: {
    Google: "L7 (Group PM)",
    Facebook: "IC7 (Product Manager)",
    Microsoft: "67 (Group PM)",
    Amazon: "L7 (Principal PM)",
    Tencent: "T15",
    Bytedance: "4-1",
    Apple: "ICT6"
  },
  L8: {
    Google: "L8 (Director)",
    Facebook: "IC8 (Director)",
    Microsoft: "68 (Partner)",
    Amazon: "L8 (Director)",
    Tencent: "T16",
    Bytedance: "4-2",
    Apple: "ICT6"
  }
}