import { yupResolver } from '@hookform/resolvers/yup';
import { history } from '_helpers';
import { authActions, userActions } from '_store';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTitle } from '../_hooks';
import { NavLink } from 'react-router-dom';

export { Login };

function Login() {

    useTitle('Verified Profiles | Login');

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.users?.current);
    const authError = useSelector(x => x.auth?.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onSubmit({ email, password }) {

        const result = await dispatch(authActions.login({ email, password }));

        if (result.meta.requestStatus === 'fulfilled') {
            await dispatch(userActions.setCurrent(result.payload.user));
        }

        return result;

    }

    return (
        <div className="d-flex justify-content-center my-5">
            <div className="card col-12 col-lg-5">
                <h4 className="card-header">Login</h4>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label>Email</label>
                            <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label>Password</label>
                            <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <div className="clearfix d-flex align-items-center justify-content-end">
                            <NavLink to="/signup" className="me-3">Sign Up</NavLink>
                            <button disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                Login
                            </button>
                        </div>
                        {authError &&
                            <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}
