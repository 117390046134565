import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { fulfilledBoilerplate, pendingBoilerplate, rejectedBoilerplate } from './shared-reducers';

//////////////////
// create slice //
//////////////////

const name = 'recruiters';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `${process.env.REACT_APP_BACKEND_API_URL}`;

/////////////
// exports //
/////////////

export const recruitersActions = { ...slice.actions, ...extraActions };
export const recruitersReducer = slice.reducer;

////////////////////
// implementation //
////////////////////

function createInitialState() {
    return {
        error: null,
        assessments: {},
        company_assessments: {},
    };
}

function createExtraActions() {

    return {
        submitCandidate: submitCandidate(),
        viewAssessment: viewAssessment(),
        getAllAssessmentsFromCompany: getAllAssessmentsFromCompany(),
        submitFeedback: submitFeedback(),
        submitCandidateEmail: submitCandidateEmail(),
        submitDocument: submitDocument(),
        submitAnswer: submitAnswer(),
        viewResume: viewResume(),
    };

    function submitCandidate() {
        return createAsyncThunk(
            `${name}/submitCandidate`,
            async (data) => await fetch(baseUrl + '/v1/processResume', {method: 'POST', body: data})
        );
    }

    function viewAssessment() {
        return createAsyncThunk(
            `${name}/viewAssessment`,
            async (id) => await fetchWrapper.get(baseUrl + '/fetchAssessment/' + id)
        );
    }

    function getAllAssessmentsFromCompany() {
        return createAsyncThunk(
            `${name}/getAllAssessmentsFromCompany`,
            async (email) => await fetchWrapper.get(baseUrl + '/assessment?$sort[createdAt]=-1&$search=' + email.split('@').slice(-1)[0])
        );
    }

    function submitFeedback() {
        return createAsyncThunk(
            `${name}/submitFeedback`,
            async ({id, feedback}) => await fetchWrapper.post(baseUrl + '/feedback', {
                assessment_id: id,
                text: feedback
            })
        );
    }

    function submitCandidateEmail() {
        return createAsyncThunk(
            `${name}/submitCandidateEmail`,
            async ({id, candidate_email}) => await fetchWrapper.post(baseUrl + '/v1/processCandidateEmail/' + id, {
                candidate_email: candidate_email
            })
        )
    }

    function submitDocument() {
        return createAsyncThunk(
            `${name}/submitDocument`,
            async ({id, formData}) => {
                const response = await fetch(baseUrl + '/v1/processDocument/' + id, {method: 'POST', body: formData});
                const text = await response.text();
                const data = text && JSON.parse(text);

                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            }
        );
    }

    function submitAnswer() {
        return createAsyncThunk(
            `${name}/submitAnswer`,
            async ({id, answer}) => await fetchWrapper.post(baseUrl + '/v1/processAnswer/' + id, answer)
        )
    }

    function viewResume() {
        return createAsyncThunk(
            `${name}/viewResume`,
            async (id) => await fetchWrapper.get(baseUrl + '/v1/fetchResume/' + id)
        )
    }
}

function createExtraReducers() {

    return {
        ...submitCandidate(),
        ...viewAssessment(),
        ...getAllAssessmentsFromCompany(),
        ...submitFeedback(),
        ...submitCandidateEmail(),
        ...submitDocument(),
        ...submitAnswer(),
        ...viewResume(),
    };

    function submitCandidate() {
        var { pending, fulfilled, rejected } = extraActions.submitCandidate;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function viewAssessment() {
        var { pending, fulfilled, rejected } = extraActions.viewAssessment;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                if (!state.assessments) {
                    state.assessments = {};
                }
                state.assessments[action.payload._id] = action.payload;
            }),
            [rejected]: rejectedBoilerplate()
        };
    }

    function getAllAssessmentsFromCompany() {
        const { pending, fulfilled, rejected } = extraActions.getAllAssessmentsFromCompany;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {

                if (!state.company_assessments) {
                    state.company_assessments = {};
                }

                if (action?.payload?.total > 0) {
                    action.payload.data.map((data) => {
                        state.company_assessments[data._id] = data;
                        return state.company_assessments[data._id];
                    });
                }

            }),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function submitFeedback() {
        var { pending, fulfilled, rejected } = extraActions.submitFeedback;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function submitCandidateEmail() {
        var { pending, fulfilled, rejected } = extraActions.submitCandidateEmail;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function submitDocument() {
        const { pending, fulfilled, rejected } = extraActions.submitDocument;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                if (!state.assessments) {
                    state.assessments = {};
                }
                state.assessments[action.payload._id] = action.payload;
            }),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function submitAnswer() {
        const { pending, fulfilled, rejected } = extraActions.submitAnswer;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                if (!state.assessments) {
                    state.assessments = {};
                }
                state.assessments[action.payload._id] = action.payload;
            }),
            [rejected]: rejectedBoilerplate(),
        };
    }

    function viewResume() {
        const { pending, fulfilled, rejected } = extraActions.viewResume;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                if (!state.resumes) {
                    state.resumes = {};
                }
                state.resumes[action.meta.arg] = action.payload;
            }),
            [rejected]: rejectedBoilerplate()
        };
    }
}
