import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { recruitersReducer } from './recruiters.slice';
import { talentReducer } from './talent.slice';

export * from './auth.slice';
export * from './users.slice';
export * from './recruiters.slice';
export * from './talent.slice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['recruiters', 'talent']
};

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    recruiters: recruitersReducer,
    talent: talentReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);
