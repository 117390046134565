import { useTitle } from '../_hooks';

export { Faq };

function Faq() {

    useTitle('Talent Rank | FAQ');

    return (
        <div className="col-md-12">

            <h1>Frequently Asked Questions</h1>
            <hr />

            <h2 className="text-secondary">How do we determine TalentRank rankings?</h2>
            <p>We firmly believe that being transparent about our AI and ranking methods is key to building a platform that our users can trust and rely on.</p>
            <p>There are three main factors we consider when ranking individuals on TalentRank:</p>
            <ol>
                <li><span className="fw-bold">User-Provided Information:</span> For those who have a profile with us, we use the information they have provided. This may include their resume, as well as any performance reviews they have uploaded that have been verified. These could be self, peer or manager reviews. We give more weight to verified and claimed profiles.</li>
                <li><span className="fw-bold">Public Information:</span> For those who have not claimed their profile, we use only publicly accessible information. This could be derived from public Github profiles, media mentions, public discussions on forums or Q&A sites, professional networks like LinkedIn, or public YouTube, Instagram and Facebook accounts.</li>
                <li><span className="fw-bold">Platform Engagement:</span> We also factor in engagement data from our platform, including profile visits, feedback and positive ratings received. Feedback and ratings from verified users have a greater impact.</li>
            </ol>

            <h2 className="text-secondary mt-4">How are profiles created on TalentRank?</h2>
            <p>There are two ways a profile can be created:</p>
            <ol>
                <li><span className="fw-bold">User-Created Profiles:</span> People can create their own profile by signing up. The ranking of their profile is primarily influenced by linking professional profiles, uploading documents such as their resume and company performance reviews, and receiving feedback from others.</li>
                <li><span className="fw-bold">Public Data-Based Profiles:</span> We also create profiles based solely on publicly available data. This could be from public Github profiles, media mentions, public forums or Q&A sites, professional networks like LinkedIn, or public Instagram and Facebook profiles.</li>
            </ol>

            <h2 className="text-secondary mt-4">Why is diversity important to us at TalentRank?</h2>
            <p>At TalentRank, we believe diversity is important because teams should reflect the varied users of the products they build. This results in products that are more suited to a broad user base. Our goal is to bring attention to and support talented individuals from underrepresented groups, such as top-ranking women in engineering. Current platforms fall short in properly recognizing and promoting the achievements of these individuals. Ultimately, we aim to build a comprehensive global talent graph that embodies diversity by including talent from all corners of the world.</p>
            
            <h2 className="text-secondary mt-4">How do I claim my TalentRank profile?</h2>
            <p>Select the "Claim Profile" button on your profile page and verify your identity. Once done, you can manage your profile and upload performance reviews.</p>

            <h2 className="text-secondary mt-4">What benefits do I get by claiming my profile?</h2>
            <p>Claiming your profile lets you take control of your professional identity, understand your ranking factors, and potentially improve your TalentRank by uploading verified performance reviews. It also enhances your visibility within your industry.</p>
        
            <h2 className="text-secondary mt-4">What happens if I decide not to claim my profile?</h2>
            <p>Unclaimed profiles are created solely from publicly available data. While these profiles are rated, claiming your profile provides more control and accuracy.</p>

            <h2 className="text-secondary mt-4">How is my performance graph generated?</h2>
            <p>The performance graph is created from data aggregated across a variety of sources, including public Github profiles, media mentions, public discussions on forums or Q&A sites, professional networks like LinkedIn, public YouTube, Instagram, Facebook accounts, and your professional tenure. Uploading performance reviews can further refine its accuracy.</p>

            <h2 className="text-secondary mt-4">How can I edit or remove information from my TalentRank profile?</h2>
            <p>We firmly believe that users should own and control their data. Once you've claimed your profile by verifying your identity, you have full control to edit or remove your information. This process is crucial to combat deception, impersonation, and misrepresentation, ensuring a safe and trustworthy environment.</p>

        </div>
    );

}
