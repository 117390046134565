import { recruitersActions } from '_store';
import { marked } from 'marked';
import { useEffect } from 'react';
import { BiEnvelope, BiPhone } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from '../_hooks';

export { Resume };

function Resume() {

    useTitle('Resume');

    const dispatch = useDispatch();
    const { resumes, error } = useSelector(x => x.recruiters);
    const { id } = useParams();
    const resume = resumes && resumes[id];

    useEffect(() => {
        // Hide nav bar from resume page
        document.getElementById('main-nav').classList.add('d-none');

        dispatch(recruitersActions.viewResume(id));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    marked.options({ headerIds: false, mangle: false });

    if (resume && resume?.status !== 'done') {
        return (
            <div className="col-md-8 mx-auto text-center rounded p-4 mb-3" style={{backgroundColor: '#f0f0f0'}}>
                <h1>We are now re-assessing the profile</h1>
                <p className="mt-4">We'll verify the answers provided and documents uploaded. We'll then reassess the candidate using this new data and generate a new report with a revised level if appropriate.</p>
            </div>
        );
    }
    else if (resume && resume?.status === 'done') {
        // Remove container paddings
        const container = document.getElementsByClassName('container')[0];
        container.style.cssText = 'padding-top: 0 !important; padding-left: 0 !important; padding-right: 0 !important';

        const description = resume?.description ? marked.parse(resume.description) : '';
        const jobDescriptions = resume?.jobs?.map(job => marked.parse(job.description));

        return (
            <div>
                <div id="candidate" className="bg-primary text-light p-3">
                    <h3>{resume?.name}</h3>
                    <span className="text-light">{resume?.title}</span>
                    <hr />
                    <p className="fs-6 lh-sm" dangerouslySetInnerHTML={{__html: description}}></p>
                </div>

                {resume?.email && resume?.phone && <div className="bg-dark p-3">
                    <ul className="list-inline d-flex align-items-center my-0">
                        {resume?.email && <li className="list-inline-item">
                            <div className="text-white d-flex">
                                <BiEnvelope size={'1.5rem'} className='me-1'/>
                                {resume?.email}
                            </div>
                        </li>}
                        {resume?.phone && <li className="list-inline-item text-white">
                            <div className="text-white d-flex">
                                <BiPhone size={'1.5rem'} className='me-1'/>
                                {resume?.phone}
                            </div>
                        </li>}
                    </ul>
                </div>}

                <h3 className="text-uppercase mt-4 mx-3">Work Experience</h3>

                {resume?.jobs?.map((job, idx) => (
                    <div className="m-3" key={idx}>
                        <h5 className="mb-0 text-primary">{job?.title}, {job?.company_name}</h5>
                        <div className="text-muted"><small>{job?.time}</small></div>
                        <p className="fs-6 lh-sm" dangerouslySetInnerHTML={{__html: jobDescriptions[idx]}}></p>
                    </div>
                ))}

                <h3 className="text-uppercase mt-4 mx-3">Education</h3>

                {resume?.education?.map((edu, idx) => (
                    <div className="m-3" key={idx}>
                        <h5 className="mb-0 text-primary">{edu?.school_name}</h5>
                        <div>
                            <span className="text-capitalize">{edu?.type_of_degree}</span>
                            in
                            <span className="text-capitalize">{edu?.major_area_studied}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    else if (error) {
        return (
            <div className="text-center">
                <h1>That's not good...</h1>
                <div className="alert alert-danger" role="alert">
                    It seems this candidate report does not exist.
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="row">
                <div className="col-md-4 mx-auto text-center">
                    <div className="spinner-grow text-primary mb-5 mt-5" role="status"></div>
                </div>
            </div>
        );
    }
}
