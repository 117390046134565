export { CandidateAssessment };

function CandidateAssessment({ assessment }) {

    if (!assessment) {
        return null;
    }

    return (
        <div id="candidate-assessment">
                <h4 className="mt-5">Assessment</h4>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Dimension</th>
                            <th scope="col">Level</th>
                            <th scope="col">Reason</th>
                        </tr>
                    </thead>
                    <tbody>

                        {assessment.map((row, idx) => {
                            return (
                                <tr key={"assessment-" + idx}>
                                    <th scope="row">{row.name}</th>
                                    <td>{row.level}</td>
                                    <td>{row.description}</td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
        </div>
    );

}