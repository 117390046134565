import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { history, subdomain, getAllUrlParams, makeUpperCase } from '_helpers';
import { authActions, userActions } from '_store';

export { Nav };

function Nav() {

    const authUser = useSelector(x => x.users.current);
    const dispatch = useDispatch();

    // eslint-disable-next-line
    const logout = async () => {
        await dispatch(authActions.logout());
        await dispatch(userActions.setCurrent(null));
        history.navigate('/login');
    };

    let result;

    if (subdomain.includes('go')) {

        const pageUrlParams = getAllUrlParams();
        let crumb = '';
        let logoUrl = '/?';

        if (history?.location?.pathname === '/' || history?.location?.pathname === '/search') {
            crumb = Object.keys(pageUrlParams).map((key) => {
                if (pageUrlParams[key]) {
                    //logoUrl += key + '=' + pageUrlParams[key] + '&';
                }
                return pageUrlParams[key] && !key.startsWith('$') && !key.startsWith('_') ? ' / ' + makeUpperCase(pageUrlParams[key]) : ''
            }).join('');
        }

        result = (
            <>

                <nav className="navbar navbar-expand-md navbar-light bg-warning scroll-point" id="main-nav">

                    <div className="container-fluid">

                        <a href={logoUrl} className="navbar-brand" style={{ maxWidth: '50%', whiteSpace: 'break-spaces' }}>
                            TalentRank{crumb}
                        </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navbar-nav ms-auto">
                                <NavLink to="/" className="nav-item nav-link text-center">Home</NavLink>
                                {authUser ?
                                    <>
                                    <NavLink to="/subscription" className="nav-item nav-link text-center">Paid Searches</NavLink>
                                    <button onClick={logout} className="btn btn-link nav-item nav-link text-center">Logout</button>
                                    </>
                                :
                                    <>
                                    <NavLink to="/login" className="nav-item nav-link text-center">Sign In</NavLink>
                                    <NavLink to="/signup" className="ms-1 nav-item text-center btn btn-success">Sign Up</NavLink>
                                    </>
                                }
                            </div>

                        </div>

                        </div>

                    </nav>

                    {false && authUser && (!authUser?.activeTalentSubscriptionPlan || authUser?.activeTalentSubscriptionPlan === 'none') &&
                        <div className="alert alert-info mb-0">You are using the free limited subscription plan. <a href="/subscription">Upgrade</a> to get full access.</div>
                    }

            </>
        );

    }
    else if (!authUser) {
        result = (
            <nav className={"d-print-none navbar navbar-expand navbar-dark bg-dark " + (subdomain && subdomain.includes('app') && 'mb-5')} id="main-nav">
                <div className="navbar-nav">
                    {/*<NavLink to="/" className="nav-item nav-link">Home</NavLink>*/}
                    <NavLink to="/recruiter" className="nav-item nav-link">Create Report</NavLink>
                    <NavLink to="/past-reports" className="nav-item nav-link">Past Reports</NavLink>
{/*                    <NavLink to="/login" className="nav-item nav-link">Login</NavLink>
                    <NavLink to="/signup" className="nav-item nav-link">Signup</NavLink>*/}
                </div>
            </nav>
        );
    }
    else {
        result = (
            <nav className={"d-print-none navbar navbar-expand navbar-dark bg-dark " + (subdomain && subdomain.includes('app') && 'mb-5')} id="main-nav">
                <div className="navbar-nav">
                    {/*<NavLink to="/" className="nav-item nav-link">Home</NavLink>*/}
                    <NavLink to="/recruiter" className="nav-item nav-link">Create Report</NavLink>
                    <NavLink to="/past-reports" className="nav-item nav-link">Past Reports</NavLink>
{/*                <NavLink to="/profile" className="nav-item nav-link">Profile</NavLink>
                    <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>*/}
                </div>
            </nav>
        );
    }

    return (
        <>
            { result }
            {authUser && authUser?.permissions.includes('admin') &&
                <div className="alert alert-danger mb-0">
                    <span className="me-1">Viewing page as an admin.</span>
                    { /*eslint-disable-next-line*/ }
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        dispatch(userActions.setIsAdmin(false));
                    }} className="alert-link">
                        Temporarily change to non-admin
                    </a>.
                </div>
            }
            { subdomain && !subdomain.includes('app') && !subdomain.includes('go') &&
            <div className="text-center mt-5 mb-5" id="white-label-wrap">
                <img id="white-label-logo" className="img-thumbnail" src={"/imgs/recruiters/" + subdomain + ".jpeg"} alt="recruiter logo" />
            </div>
            }
        </>
    );

}