import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { pendingBoilerplate, fulfilledBoilerplate, rejectedBoilerplate } from './shared-reducers';

//////////////////
// create slice //
//////////////////

const name = 'talent';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
const baseUrl = `${process.env.REACT_APP_BACKEND_API_URL}`;

/////////////
// exports //
/////////////

export const talentActions = { ...slice.actions, ...extraActions };
export const talentReducer = slice.reducer;

////////////////////
// implementation //
////////////////////

function createInitialState() {
    return {
        error: null,
    };
}

function createExtraActions() {

    return {
        searchAvailableTalentByFilter: (() => {
            return createAsyncThunk(
                `${name}/searchAvailableTalentByFilter`,
                async ({query}) => {
                    return await fetchWrapper.get(`${baseUrl}/talent-graph/?${query}`);
                }
            );
        })(),
        submitTalentFeedback: (() => {
            return createAsyncThunk(
                `${name}/submitTalentFeedback`,
                async ({ feedbackText, _id, meta }) => {
                    return await fetchWrapper.patch(`${baseUrl}/talent-graph/${_id}`, { feedbackText, _id, meta });
                }
            );
        })(),
        getSubscriptionOptions: (() => {
            return createAsyncThunk(
                `${name}/getSubscriptionOptions`,
                async () => {
                    return await fetchWrapper.get(`${baseUrl}/stripe/plans/`);
                }
            );
        })(),
    };

}

function createExtraReducers() {

    return {
        ...searchAvailableTalentByFilter(),
        ...submitTalentFeedback(),
        ...getSubscriptionOptions(),
    };

    function searchAvailableTalentByFilter() {
        var { pending, fulfilled, rejected } = extraActions.searchAvailableTalentByFilter;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                if (action?.payload?.total > 0) {
                    state.talent_available_to_show = action.payload.data;
                }
            }),            
            [rejected]: rejectedBoilerplate(),
        };
    }

    function submitTalentFeedback() {
        var { pending, fulfilled, rejected } = extraActions.submitTalentFeedback;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),            
            [rejected]: rejectedBoilerplate(),
        };
    }

    function getSubscriptionOptions() {
        var { pending, fulfilled, rejected } = extraActions.getSubscriptionOptions;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),            
            [rejected]: rejectedBoilerplate(),
        };
    }

}
