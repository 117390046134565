import * as React from 'react';
import { isMobile } from 'react-device-detect';

function BuyButton({ 
    price = '$9.99', 
    cta = 'Unlock Full List', 
    title = 'Talent Subscription',
    save = '',
    url,
    recurring = {
        interval_count: 1,
        interval: 'month'
    },
    onClick,
    email = '',
}) {

    const uuidv4 = function() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        /*eslint-disable-next-line*/
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    const btnWidth = isMobile ? '80%' : '65%';

    return (
        <div className="d-flex flex-column align-items-center">
            <h6>{title}</h6>
            <div className="d-flex flex-row align-items-center mb-3">
                <span className="h3 mb-0 me-1">{price}</span>
                <span className="d-flex flex-column" style={{ fontSize: 10 }}>
                    <span style={{ lineHeight: 1 }}>{recurring.interval_count === 1 ? 'per' : 'every'}</span>
                    <span style={{ lineHeight: 1 }}>{recurring.interval_count === 1 ? recurring.interval : recurring.interval_count + ' months'}</span>
                </span>
            </div>
            <div className="position-relative text-center" style={{ width: btnWidth }}>
                <button disabled={false} className="btn btn-success px-5 py-2 mb-2 w-100" onClick={() => {
                    const crd = uuidv4();
                    window.open(url + '?client_reference_id=' + crd + (email && '&prefilled_email=' + email), '_blank');
                    onClick(crd);
                }}>
                    <span style={{ fontSize: 16 }}>{cta}</span>
                    {save && <span className="position-absolute px-1" style={{ 
                        top: 0, right: 0, background: '#ffde92', color: '#bb5503', borderTopRightRadius: 4, borderBottomLeftRadius: 4, fontSize: 9
                    }}>{save}</span>}
                </button>
            </div>
            <p className="text-muted small mb-0">Supported payment methods:</p>
            <div>
                <img className="me-1" src="/imgs/payments/amex.svg" alt="amex" />
                <img className="me-1" src="/imgs/payments/mastercard.svg" alt="mastercard" />
                <img className="me-1" src="/imgs/payments/visa.svg" alt="visa" />
                <img className="me-1" src="/imgs/payments/google_pay.svg" alt="google_pay" />
                <img src="/imgs/payments/cashapp.svg" alt="cashapp" />
            </div>
        </div>
    );

}

export { BuyButton };