export function pendingBoilerplate(reducerFunction, sliceName) {
    return (state, action) => {
        let _s = sliceName ? state[sliceName] : state;
         // eslint-disable-next-line
        _s.loading = action.type.endsWith('/pending');
        _s.error = null;
        _s.loadingActionName = action?.type?.split('/')[1];
        return reducerFunction ? reducerFunction(state, action) : (() => {})();
    };
};

export function fulfilledBoilerplate(reducerFunction, sliceName) {
    return (state, action) => {
         // eslint-disable-next-line
        let _s = sliceName ? state[sliceName] : state;
        const result = reducerFunction ? reducerFunction(state, action) : (() => {})();
        state.loading = false;
        state.error = null;
        state.loadingActionName = '';
        return result;
    };
};

export function rejectedBoilerplate(reducerFunction, sliceName) {
    return (state, action) => {
        let _s = sliceName ? state[sliceName] : state;
        _s.loading = !action.type.endsWith('/rejected');
        _s.loadingActionName = '';
        if (action.error) {
            _s.error = action.error;
            console.error(action);
        }
        return reducerFunction ? reducerFunction(state, action) : (() => {})();
    };
};