import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';

export { Question };

function Question({ dimension, question, prev, next, submit }) {

    // form validation rules
    const validationSchema = Yup.object().shape({
        answer: Yup.string()
            .required('Textarea value is required'),
            // .test("word-count", "Word count needs more than 200", (words) => words.length >= 200),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = async ({answer}) => {
        if (!!next) {
            await next(dimension, answer);
        } else if (!!submit) {
            await submit(dimension, answer);
        }
    };

    return (
        <>
            <div>
                <p className="text-muted">Describe your {dimension}:</p>
                <ul>
                    <li>{question[0]}</li>
                    <li>{question[1]}</li>
                </ul>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <textarea
                            {...register('answer')}
                            className={`text-start form-control ${errors.answer ? 'is-invalid' : ''}`}
                            rows="5"
                            disabled={isSubmitting}
                        ></textarea>
                        <div className="invalid-feedback text-start">{errors?.answer?.message}</div>
                    </div>

                    <div className="text-end mt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            className={`${!prev ? 'd-none' : ''}`}
                            onClick={prev}
                        >
                            Prev
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className={`${!next ? 'd-none' : ''} ms-3`}
                        >
                            Next
                        </Button>
                        <Button
                            type="submit"
                            variant="success"
                            disabled={isSubmitting}
                            className={`${!submit ? 'd-none' : ''} ms-3`}
                        >
                            Submit
                            {isSubmitting && <span className="spinner-border spinner-border-sm ms-2"></span>}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}
