import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTitle } from '../_hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { talentActions } from '_store';
import './style.css';
import { ChevronDoubleUp, ChevronDoubleDown, Linkedin, Instagram, Github, Twitter, Youtube, Medium, Globe, Twitch, Facebook, CircleFill, Tiktok, PatchCheckFill } from 'react-bootstrap-icons';
import { PaymentModal } from '../_components';
import { getAllUrlParams, history, enableTooltips } from '../_helpers';
import { isMobile } from 'react-device-detect';
import ConfettiExplosion from 'confetti-explosion-react';

export { SearchRank };

const paginationPerPage = 50;
const forcedFilters = {
    city: 'san+francisco',
    gender: 'female',
    jobTitle: 'software+engineer',
};

function SearchRank() {

    useTitle('Talent Rank | Verified Profiles');

    const sortedByKey = 'defaultScore';
    const specialityKeyNames = [{
        internal: 'ai',
        display: 'AI'
    }, {
        internal: 'security',
        display: 'Security'
    }, {
        internal: 'mobileIos',
        display: 'IOS'
    },
    {
        internal: 'mobileAndroid',
        display: 'Android'
    },
    {
        internal: 'ml',
        display: 'ML'
    }];

    const [ currentSearchResults, setCurrentSearchResults ] = useState([]);
    const [ currentSearchResultsStartIndex, setCurrentSearchResultsStartIndex ] = useState(0);
    const [ totalAvailableResultsNum, setTotalAvailableResultsNum ] = useState(0);
    const [ isCurrentSearchPaidFor, setIsCurrentSearchPaidFor ] = useState(false);
    const [ subscriptionOptions, setSubscriptionOptions ] = useState({});
    const [ hasSearchedAtLeastOnce, setHasSearchedAtLeastOnce ] = useState(false);
    const [ hasClickedSearchedAtLeastOnce, setHasClickedSearchedAtLeastOnce ] = useState(false);
    const [ isPaymentOpen, setIsPaymentOpen ] = useState(false);
    const [ confettiEnded, setConfettiEnded ] = useState(false);

    const dispatch = useDispatch();
    const { loading } = useSelector(x => x.talent);
    const authUser = useSelector(x => x.users?.current);
    const isAdmin = authUser?.permissions?.includes('admin') || false;

    const { register, handleSubmit, getValues: getFilterValues, setValue: setFilterValues } = useForm();

    const pageUrlParams = getAllUrlParams();

    const doSearch = async( allFilters ) => {

        console.log('Searching data...');

        const { $sort } = allFilters;
        const $skip = allFilters?.$skip || 0;
        let buildQuery = '';

        Object.keys(allFilters).forEach((filter_name) => {

            filter_name = decodeURIComponent(filter_name);

            if (allFilters[filter_name] && !filter_name.startsWith('_') && !filter_name.startsWith('~')) {

                if (filter_name.startsWith('$')) {

                    if (filter_name === '$sort') {
                        buildQuery += `${filter_name}[${sortedByKey}]=${allFilters[filter_name]}`;
                    }
                    else if (filter_name === '$experience') {
                        let exp = parseInt(allFilters[filter_name], 10),
                            thisYear = new Date().getFullYear(),
                            dir = '$lte';

                        if (exp === 0) {
                            dir = '$gte';
                        }

                        buildQuery += 'bachelorsGraduationYear[' + dir + ']=' + (thisYear - exp);

                    }

                }
                else {
                    buildQuery += `${filter_name}[$search]=${allFilters[filter_name]}`;
                }
            }
            else if (allFilters[filter_name] && filter_name.startsWith('~')) {
                buildQuery += `${allFilters[filter_name]}=1`;
            }

            if (allFilters[filter_name]) {
                buildQuery += '&';
            }

        });

        buildQuery = buildQuery.slice(0, -1) + '&$skip=' + $skip;

        dispatch(talentActions.searchAvailableTalentByFilter({ query: buildQuery })).then((_results) => {
            handleSearchResult(_results, $skip, $sort);
        });

    };

    const handleSearchResult = (_results, $skip = 0, $sort = '-1') => {

        if (_results?.payload?.total > 0) {
            const actualResultsLen = _results?.payload?.data?.people?.length;
            setCurrentSearchResults(actualResultsLen > 0 ? sortedBy(_results?.payload?.data?.people, sortedByKey, $sort) : []);
            setCurrentSearchResultsStartIndex(parseInt($skip, 10));
            setTotalAvailableResultsNum(_results?.payload?.total);
        }
        else {
            setCurrentSearchResults([]);
        }

        if (!hasSearchedAtLeastOnce) {
            setHasSearchedAtLeastOnce(true);
        }

        setIsCurrentSearchPaidFor(_results?.payload?.data?.isPaidForSearch || false);

    };

    const sortedBy = (_data, _key, _sortBy) => {

        return [..._data].sort((a, b) => {
            return _sortBy === '-1' ? b[_key] - a[_key] : a[_key] - b[_key];
        });

    };

    const InfoModal = ({ content, title = "Information", id }) => {

        return (

            <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {content}
                        </div>
                        <div className="modal-footer">
                        {id === 'needLoginModal' ?
                            <>
                            <a href="/login" role="button" className="btn btn-outline-dark">Sign In</a>
                            <a href="/signup" role="button" className="btn btn-success">Sign Up</a>
                            </>
                        :
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                        }
                        </div>
                    </div>
                </div>
            </div>

        );

    }

    const renderRecruiterUpsell = () => {

        return (
            <li key={'recriter-upsell'} className="list-group-item scroll-point">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center pt-5 pb-5">
                            <h5>Are you a recruiter? Get early access to AI powered talent evaluation</h5>
                            <p>Boost recruitment efficiency and uncover hidden talent with our advanced AI tools</p>
                            <a href='https://verifiedprofiles.ai/recruiters' className="btn btn-secondary">Get Early Access</a>
                        </div>
                    </div>
                </div>
            </li>
        );

    };

    const renderUnlockRow = () => {

        if (isCurrentSearchPaidFor) {
            return (null);
        }

        return (
            <li key={'person-unlock'} className="list-group-item scroll-point">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center pt-5 pb-5">
                            <h5>Unlock all results</h5>
                            { !authUser &&
                            <>
                                <p>You need to join and verify to unlock the rest of the rankings</p>
                                <Link to='/signup' className="btn btn-primary">Sign Up</Link>
                            </>
                            }
                            { authUser &&
                            <>
                                <p>You are using the free limited subscription plan.</p>
                                {/*eslint-disable-next-line*/}
                                <a tabIndex="-1" role="button" aria-disabled="true" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setIsPaymentOpen(true);
                                }}>Upgrade to get full access</a>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </li>
        );

    };

    const PersonRowSearchResult = ({person, rankNo}) => {

        const iconSize = isMobile ? 36 : 20;
        const [ feedbackText, setFeedbackText ] = useState('');
        const [ savingRating, setSavingRating ] = useState(false);

        const onClickSocial = (e) => {
            if (!isCurrentSearchPaidFor) {
                e.preventDefault();
                if (authUser) {
                    setIsPaymentOpen(true);
                }
            }
        };

        const renderRankMovement = () => {

            if (person.firstName.includes('a')) {
                return (
                    <div style={{ marginTop: -6 }} className="me-0 me-md-1">
                        <ChevronDoubleUp color="green" size={30} />
                    </div>
                );
            }
            else if (person.firstName.includes('i')) {
                return (
                    <div style={{ marginTop: -6 }} className="me-0 me-md-1">
                        <ChevronDoubleDown color="red" size={30} />
                    </div>
                );
            }

            return null;

        };

        return (
            <li className="list-group-item mb-3 rounded scroll-point">
                <div className="container">
                    <div className="row my-2">

                        <div className="col-sm-5 text-center d-flex flex-lg-row flex-column align-items-center justify-content-center">
                            { renderRankMovement() }
                            <h2 className="me-lg-4 text-muted">#{rankNo + 1}</h2>
                            <div className="col-9 col-sm-12 col-lg-9">
                                <a
                                    href={person.linkedinProfileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={onClickSocial}
                                    {...(!authUser && { 'data-bs-toggle': "modal" })}
                                    {...(!authUser && { 'data-bs-target': "#needLoginModal" })}
                                >
                                    <img
                                        src={'/imgs/talent-images/' + person.imageName}
                                        alt={person.firstName}
                                        className="img-thumbnail rounded-circle"
                                        onError={(e) => {
                                            if (e?.target?.src !== person?.linkedinImageUrl) {
                                                console.error('Local image failed to load for:', person);
                                                e.target.src = person?.linkedinImageUrl;
                                            }
                                        }}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-7 d-flex flex-column text-center text-sm-start justify-content-center">
                            <p className="h2 mt-3 mt-md-0 d-flex align-items-center justify-content-center justify-content-md-start">

                                <span className="me-2">{person?.firstName?.trim()}</span>
                                <span className="blurShowFirst" dangerouslySetInnerHTML={{
                                    __html: person?.lastName?.trim()?.replaceAll('lastnameremaining', 'span')
                                }} />

                                {person?.featured &&
                                <span
                                    data-bs-custom-class="custom-tooltip"
                                    data-bs-toggle="tooltip"
                                    title="This person has verified their identity with TalentRank"
                                    className="d-flex ms-2"
                                    style={{ marginTop: 2, cursor: 'help' }}
                                >
                                    <PatchCheckFill color="#027bff" size={20} />
                                </span>}

                            </p>
                            <p className="mb-0">{person.city && (person.city.trim())}{person.city !== person.country ? ', ' + person.country.trim() : ''}</p>
                            <p className="mb-0">{person.jobTitle && (person.jobTitle.trim())} <span className="badge bg-dark">{person.mostRecentCompany}</span></p>
                            <div className="d-flex align-items-center justify-content-center justify-content-sm-start ms-n1">
                                {
                                    specialityKeyNames.map(function(key) {
                                        return person[key.internal] ?<span className="badge bg-secondary me-1 mt-1">{key.display}</span> : null;
                                    })
                                }
                            </div>
                            { isAdmin && <p className="mb-0 text-danger">Featured: {person?.featured ? 'Yes' : 'No'}</p> }
                            { isAdmin && <p className="mb-0 text-danger">Score: {person[sortedByKey]}</p> }
                            { isAdmin && <p className="mb-0 text-danger">Ethnicity: {person.ethnicity}</p> }
                            <div className={isMobile ? 'mt-2 me-n2' : 'mt-1'}>
                                {/*eslint-disable-next-line*/}
                                {
                                    person.twitter && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.twitter}  onClick={onClickSocial}>
                                        <Twitter color="#1d9bf0" size={iconSize} />
                                    </a>
                                }
                                {/*eslint-disable-next-line*/}
                                {
                                    person.youtube && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.youtube}  onClick={onClickSocial}>
                                        <Youtube color="#ff0000" size={iconSize} />
                                    </a>
                                }
                                {/*eslint-disable-next-line*/}
                                {
                                    person.github && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.github}  onClick={onClickSocial}>
                                        <Github color="#000" size={iconSize} />
                                    </a>
                                }
                                {/*eslint-disable-next-line*/}
                                {
                                    person.ig && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.ig}  onClick={onClickSocial}>
                                        <Instagram color="#E1306C" size={iconSize} />
                                    </a>
                                }
                                {/*eslint-disable-next-line*/}
                                {
                                    person.medium && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.medium}  onClick={onClickSocial}>
                                        <Medium color="#000" size={iconSize} />
                                    </a>
                                }
                                {
                                    person.website && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.website}  onClick={onClickSocial}>
                                        <Globe color="#000" size={iconSize} />
                                    </a>
                                }
                                {
                                    person.twitch && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.twitch}  onClick={onClickSocial}>
                                        <Twitch color="#8b44f7" size={iconSize} />
                                    </a>
                                }
                                {
                                    person.facebook && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.facebook}  onClick={onClickSocial}>
                                        <Facebook color="#027bff" size={iconSize} />
                                    </a>
                                }
                                {
                                    person.vsco && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.vsco}  onClick={onClickSocial}>
                                        <CircleFill color="#000000" size={iconSize} />
                                    </a>
                                }
                                {
                                    person.tiktok && <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.tiktok}  onClick={onClickSocial}>
                                        <Tiktok color="#000000" size={iconSize} />
                                    </a>
                                }
                                {/*eslint-disable-next-line*/}
                                <a {...(!authUser && { 'data-bs-toggle': "modal" })} {...(!authUser && { 'data-bs-target': "#needLoginModal" })} tabIndex="-1" target="_blank" rel="noreferrer" role="button" aria-disabled="true" className="me-2" href={person.linkedinProfileUrl}  onClick={onClickSocial}>
                                    <Linkedin size={iconSize} />
                                </a>
                            </div>
                            <p className="mt-4 align-self-center align-self-md-end">
                                <button type="button" className="btn btn-outline-dark me-1 mb-1 mb-xs-0 d-none" onClick={async () => {
                                    if (!authUser) {
                                        window.$('#needLoginModal').modal({show: true});
                                        return;
                                    }
                                }}>
                                    Claim profile
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark mb-1 mb-xs-0"
                                    data-bs-toggle="collapse" data-bs-target={'#feedback-' + person._id} aria-expanded="false" aria-controls="collapseExample"
                                >
                                    Leave feedback
                                </button>
                            </p>

                        </div>

                        <div className="collapse col-md-12 mt-0 mt-lg-2" id={'feedback-' + person._id}>
                            <textarea
                                placeholder="Please let us know what feedback you have about this profile..."
                                className="form-control"
                                rows="3"
                                value={feedbackText || ''}
                                onChange={e => setFeedbackText(e.target.value)}
                            />
                            <button className="btn btn-primary mt-1 float-end mb-3 mb-lg-0" onClick={async () => {

                                if (!authUser) {
                                    window.$('#needLoginModal').modal({show: true});
                                    return;
                                }

                                dispatch(talentActions.submitTalentFeedback({
                                    feedbackText,
                                    _id: person._id,
                                    meta: person
                                }));

                                window.$('#feedbackSuccessModal').modal({show: true});

                            }} disabled={loading || !feedbackText}>
                                  {loading && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>}
                                  <span>Submit</span>
                            </button>
                        </div>

                        <div className="container col-md-12 ratings-wrap text-center mt-0 mt-lg-4" >
                            <p className="mb-1">How would you rate as <span className="fw-bold">{person.firstName || person.lastName}</span> as a <span className="fw-bold">{person.jobTitle}</span>?</p>
                            <div
                                className="starrating risingstar d-flex justify-content-center flex-row-reverse"
                                {...(!authUser && { 'data-bs-toggle': "modal" })}
                                {...(!authUser && { 'data-bs-target': "#needLoginModal" })}
                            >
                                <>
                                    {(() => {
                                        const arr = [];
                                        for (let i = 10; i > 0; i--) {
                                            arr.push(
                                                <>
                                                    <input
                                                        type="radio"
                                                        id={`star${i}-${person._id}`}
                                                        name={"rating-" + person._id}
                                                        value={i}
                                                        onClick={(el) => {
                                                            if (!authUser) {
                                                                return el.preventDefault();
                                                            }
                                                            setSavingRating(true);
                                                            console.log(person._id, i);
                                                            setTimeout(() => {
                                                                setSavingRating(false);
                                                            }, 1000)
                                                        }}
                                                    />
                                                    <label
                                                        className="me-2"
                                                        htmlFor={`star${i}-${person._id}`}
                                                        title={`${i} star`}><span>{i}</span>
                                                    </label>
                                                </>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </>
                            </div>
                            {savingRating && <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="spinner-grow text-dark" role="status" />
                                <div className="ms-2">Saving...</div>
                            </div>}
                        </div>

                    </div>
                </div>
            </li>
        );

    };

    const renderNoResults = () => {

        return (
            <li key={'no-results'} className="list-group-item scroll-point">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center pt-5 pb-5">
                            {hasSearchedAtLeastOnce && <h4 className="text-center text-danger">Sorry, we did not have any results at this time.</h4>}
                        </div>
                    </div>
                </div>
            </li>
        );

    };

    const updateUrlWithNewSkipPosition = (newIndex) => {

        let addPaginationToUrl = decodeURIComponent(history.location.search);
        if (addPaginationToUrl.indexOf('$skip=') === -1) {
            addPaginationToUrl += '&$skip=' + newIndex
        }
        else {
            var searchParams = new URLSearchParams(addPaginationToUrl);
            searchParams.set('$skip', newIndex);
            addPaginationToUrl = searchParams.toString();
        }

        history.navigate({
            search: addPaginationToUrl
        });

    };

    const updateUrlWithCurrentFilters = () => {

        let formState = getFilterValues();
        delete formState.$sort;

        // remove empty values
        formState = Object.fromEntries(Object.entries(formState).filter(([_, v]) => (v !== '' && v !== null && v !== undefined)));

        history.navigate({
            search: new URLSearchParams(formState).toString()
        });

    };

    const renderPagination = () => {

        return (
            <div className="container pr-0 mb-3 scroll-point">
                <div className="row">
                    <div className="col-12">
                        {/*currentSearchResultsStartIndex - paginationPerPage*/}
                        {/*currentSearchResultsStartIndex*/}
                        <ul className="pagination justify-content-end">
                            <li className="page-item">
                                {/*eslint-disable-next-line*/}
                                <a className="page-link" href="#" onClick={(e) => {

                                    e.preventDefault();

                                    const newIndex = Math.max(0, currentSearchResultsStartIndex - paginationPerPage);
                                    doSearch({
                                        ...getFilterValues(),
                                        $skip: newIndex
                                    });

                                    updateUrlWithNewSkipPosition(newIndex);

                                }}>Previous</a>
                            </li>
                            {currentSearchResults?.length > 0 && <li className="page-item ms-2">
                                {/*eslint-disable-next-line*/}
                                <a className="page-link" href="#" onClick={(e) => {

                                    e.preventDefault();
                                    window.scrollTo(0, 0);

                                    const newIndex = currentSearchResultsStartIndex + paginationPerPage;
                                    doSearch({
                                        ...getFilterValues(),
                                        $skip: newIndex
                                    });

                                    updateUrlWithNewSkipPosition(newIndex);

                                }}>Next</a>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        );

    };

    const BackButtonListener = () => {

        const [pressed, setPressed] = useState(false);
        useEffect(() => {
            window.onpopstate = e => {
                setPressed(true);
            };
        });

        // Do back button work here
        if (pressed) {
            !loading && doSearch({
                ...getFilterValues(),
                ...pageUrlParams,
            });
            console.log('Back event detected...');
        }

        return null;

    };

    let listItems = [];

    if (currentSearchResults?.length === 0 && !loading) {
        listItems.push(renderNoResults());
    }
    else {
        listItems = listItems?.concat(currentSearchResults?.map((person, idx) => {
            return <PersonRowSearchResult key={'person-' + person._id} person={person} rankNo={isCurrentSearchPaidFor ? currentSearchResultsStartIndex + idx : idx} />;
        }));
    }

    // scroll results into view
    useEffect(() => {
        if (hasClickedSearchedAtLeastOnce && currentSearchResults.length > 0) {
            document?.getElementById('main-content')?.scrollIntoView({
                behavior: 'smooth',
            });
        }
        enableTooltips();
    }, [currentSearchResults, hasClickedSearchedAtLeastOnce]);

    ///////////////////////////////
    // initial page load results //
    ///////////////////////////////

    useEffect(() => {

        let searchParams = pageUrlParams;

        // force all searches to be san francisco for initial launch
        if (!isAdmin) {
            searchParams = {
                ...searchParams,
                ...forcedFilters,
            };
            console.warn('Override location, forcing SF');
        }

        console.log('Initializing page...', searchParams);

        doSearch({
            //$skip: 0,
            ...getFilterValues(),
            ...searchParams,
        });

        // set filters visibly
        // eslint-disable-next-line
        Object.keys(searchParams).map((key) => {
            const el = document.getElementById(key);
            if (el) {
                let val = searchParams[key].replaceAll('+', ' ');
                if (key !== '~speciality') {
                    val = val.toLowerCase();
                }
                setFilterValues(key, val);
            }
        });

        dispatch(talentActions.getSubscriptionOptions()).then((_results) => {
            if (_results?.payload) {
                const subscriptions = _results.payload.reduce((acc, subscription) => {
                    acc[subscription.payment_link.id] = subscription;
                    return acc;
                }, {});
                setSubscriptionOptions(subscriptions);
            }
        });

        document.querySelector("#confetti-container > div:first-of-type")?.addEventListener("animationend", () => {
            if (!confettiEnded) {
                setConfettiEnded(true);
            }
        }, {once : true});

        // use this to scope the css
        document.documentElement.className = 'talent-rank';
        return function cleanup() {
            document.documentElement.classList.remove('talent-rank');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////
    // render //
    ////////////

    console.log('Rendering...');
    const filterValsDisplayTitle = getFilterValues();
    filterValsDisplayTitle.jobTitle = (filterValsDisplayTitle.jobTitle && filterValsDisplayTitle.jobTitle + 's') || 'People';

    return (
        <div className="container px-0">

            <InfoModal
                id="feedbackSuccessModal"
                title="Feedback Submitted"
                content="Thank you for your feedback. We will contact you if we have any questions."
            />

            <InfoModal
                id="needLoginModal"
                title="Signup To Access Talent Rank"
                content="Our AI focuses on diversity, aims to promote equality and ranks potential candidates in real-time. We use a blend of public data, peer feedback, and verified ratings. We factor in demand for each individual using user interest or engagement signals."
            />

            <div className="row scroll-point">
                <div className="col-md-12">
                    <div className="alert alert-light">
                        <p>TalentRank aims to promote <span className="fw-bold">equality</span>. We're currently focusing on <span className="fw-bold">under represented</span> talent groups, such as identifying the top ranked women in engineering.</p>
                        <hr />
                        <p className="mb-0">Our goal is to build a comprehensive global talent graph. Learn more <a href="/faq">here</a>.</p>
                    </div>
                </div>
            </div>

            {
                pageUrlParams?._success && !pageUrlParams?.$skip && <>
                {!confettiEnded &&
                <div className="d-flex justify-content-center" id="confetti-container">
                    <ConfettiExplosion force={0.6} duration='5000' particleCount='300' height='3000' width='1600' />
                </div>
                }
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Payment successful!</h4>
                    <p>Full access granted to the Talent Rank for Female Engineers in San Francisco. Rankings are dynamically update as people's roles and experiences evolve, either via public data or profile updates - dive in and explore!</p>
                    <hr />
                    <p className="mb-0">Be sure to check back regularly, as new people are added daily.</p>
                </div>
                </>
            }

            <div className="row">

                <div className="col-md-9 mb-3">

                    { !loading && <BackButtonListener /> }

                    <div className="mt-3 mb-3 d-sm-none d-md-block">
                        <h2 className="text-start" style={{ textTransform: 'capitalize' }}>
                            Top {filterValsDisplayTitle.gender} {filterValsDisplayTitle.jobTitle} {filterValsDisplayTitle.city && 'in ' + filterValsDisplayTitle.city}
                        </h2>
                    </div>

                    <ul className="list-group" id="main-content">
                        {loading &&
                            <li key="loading" className="list-group-item text-center pt-5 pb-5 scroll-point">
                                <button className="btn btn-secondary" type="button" disabled><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span><span className="pl-2">Loading...</span></button>
                            </li>
                        }
                        { listItems }
                        { !loading && listItems && isCurrentSearchPaidFor && renderPagination() }
                        { !loading && currentSearchResults?.length > 0 && renderUnlockRow() }
                        { renderRecruiterUpsell() }
                    </ul>

                </div>

                <div className="col-md-3 order-first order-md-last mt-4 scroll-point">

                    <h5 className="mb-3 mt-md-2">Search by Attributes</h5>

                    <form onSubmit={handleSubmit(async (data) => {
                        return doSearch({
                            ...data,
                            $skip: 0,
                        });
                    })}>

                        <div className={"input-group mb-3 " + (isAdmin && "border border-danger")}
                            {...(!isAdmin && { 'data-bs-custom-class': 'custom-tooltip', 'data-bs-toggle': 'tooltip', 'title': 'This filter is not available to you yet.' })}
                        >
                                <label className="input-group-text" htmlFor="city">City</label>
                                <select className="form-select" id="city" {...register('city')} disabled={!isAdmin}>
                                    <option value="">-</option>
                                    <option value="san francisco">San Francisco</option>
                                    <option value="london">London</option>
                                </select>
                        </div>

                        <div className={"input-group mb-3 " + (isAdmin && "border border-danger")}
                            {...(!isAdmin && { 'data-bs-custom-class': 'custom-tooltip', 'data-bs-toggle': 'tooltip', 'title': 'This filter is not available to you yet.' })}
                        >
                                <label className="input-group-text" htmlFor="jobTitle">Title</label>
                                <select className="form-select" id="jobTitle" {...register('jobTitle')} disabled={!isAdmin}>
                                    <option value="">-</option>
                                    <option value="software engineer">Software Engineer</option>
                                    <option value="product manager">Product Manager</option>
                                    <option value="recruit">Recruiter</option>
                                    <option value="manager">Manager</option>
                                </select>
                        </div>

                        <div className={"input-group mb-3 " + (isAdmin && "border border-danger")}
                            {...(!isAdmin && { 'data-bs-custom-class': 'custom-tooltip', 'data-bs-toggle': 'tooltip', 'title': 'This filter is not available to you yet.' })}
                        >
                            <label className="input-group-text" htmlFor="gender">Gender</label>
                            <select className="form-select" id="gender" {...register('gender')} disabled={!isAdmin}>
                                <option value="">-</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                                <option value="non binary">Non Binary</option>
                            </select>
                        </div>

                        <div className={"input-group mb-3"}>
                            <label className="input-group-text" htmlFor="~speciality">Speciality</label>
                            <select className="form-select" id="~speciality" {...register('~speciality')}>
                                <option value="">-</option>
                                <option value="generalist">Generalist</option>
                                <option value="ai">AI</option>
                                <option value="security">Security</option>
                                <option value="mobileIos">IOS</option>
                                <option value="mobileAndroid">Android</option>
                                <option value="ml" disabled>ML</option>
                            </select>
                        </div>

                        <div className={"input-group mb-3"}>
                            <label className="input-group-text" htmlFor="$experience">Experience</label>
                            <select className="form-select" id="$experience" {...register('$experience')}>
                                <option value="">-</option>
                                <option value="0">Student / Intern</option>
                                <option value="3">3+ years</option>
                                <option value="10">10+ years</option>
                                <option value="20">20+ years</option>
                            </select>
                        </div>

                        {isAdmin &&
                        <div className={"input-group mb-3 " + (isAdmin && "border border-danger")}>
                            <label className="input-group-text" htmlFor="ethnicity">Ethnicity</label>
                            <select className="form-select" id="ethnicity" {...register('ethnicity')}>
                                <option value="">-</option>
                                <option value="asian">Asian</option>
                                <option value="black">Black</option>
                                <option value="white">Caucasian</option>
                                <option value="latino">Hispanic or Latino</option>
                            </select>
                        </div>
                        }

                        {isAdmin &&
                        <div className={"input-group mb-3 " + (isAdmin && "border border-danger")}>
                            <label className="input-group-text" htmlFor="$sort">Score</label>
                            <select className="form-select" id="$sort" {...register('$sort')}>
                                <option value="-1">Highest First</option>
                                <option value="1">Lowest First</option>
                            </select>
                        </div>
                        }

                        <div className="text-center text-lg-end mb-3">
                            <button type="submit"
                                {...(!authUser && { 'data-bs-toggle': "modal" })}
                                {...(!authUser && { 'data-bs-target': "#needLoginModal" })}
                                className="btn btn-primary"
                                onClick={(e) => {
                                    if (authUser) {
                                        updateUrlWithCurrentFilters();
                                        setHasClickedSearchedAtLeastOnce(true);
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }}
                                disabled={loading}
                            >
                                See results
                                {loading && hasSearchedAtLeastOnce && <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>}
                            </button>
                        </div>

                    </form>

                    <div className="my-5">
                        <h5 className="mb-3 mt-md-2">How do rankings work?</h5>
                        <p>Our AI <a href="/faq">ranks</a> individuals in real-time. We use a blend of public data, peer feedback, and verified ratings. We factor in demand for each individual using user interest or engagement signals.</p>
                        <p>For accurate leveling, we use industry-standard, role-specific guidelines. This provides an unbiased assessment of experience. People can provide us with non public data to improve the accuracy of their level assessment.</p>
                    </div>

                    <div className="mb-5 text-center">
                        <img src="/imgs/trait.png" className="img-fluid w-50" alt="trait logo" />
                    </div>
                </div>

            </div>

            <PaymentModal
                show={isPaymentOpen}
                tellParent={setIsPaymentOpen}
                filters={getFilterValues}
                totalAvailableResultsNum={totalAvailableResultsNum}
                subscriptionOptions={subscriptionOptions}
            />

        </div>
    );

}
