import { useSelector } from 'react-redux';
import { useTitle } from '../_hooks';

export { Profile };

function Profile() {

    useTitle('Verified Profiles | Profile');

    const authUser = useSelector(x => x.users?.current);

    return (
        <div>
            <h1>Hi {authUser?.firstName}!</h1>
            {authUser && <p>You're logged in. This is a private route.</p>}
            <h3>User profile info:</h3>
            {authUser && <pre>{JSON.stringify(authUser, null, 4)}</pre>}
            {!authUser && <div className="text-danger">Error getting local user profile</div>}
        </div>
    );

}
