import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom';
import { recruitersActions } from '_store';
import { useTitle } from '../_hooks';

export { PastReports };

function PastReports() {

    useTitle('Verified Profiles | Past Reports');

    const dispatch = useDispatch();
    const { assessments, company_assessments } = useSelector(x => x.recruiters);
    const lastEmailUsed = Cookies.get('last-email-used');

    let past_local_reports = Cookies.get('past-reports');
    if (!past_local_reports) {
        past_local_reports = {};
    }
    else {
        past_local_reports = JSON.parse(past_local_reports);
        if (Array.isArray(past_local_reports)) {
            past_local_reports = convertArrayToObject(past_local_reports, 'id');
        }
    }

    // fetch remote state
    useEffect(() => {

        // get other reports from recruiters at this company
        dispatch(recruitersActions.getAllAssessmentsFromCompany(lastEmailUsed));

        // get the status of each local report
        Object.keys(past_local_reports).forEach((_id) => {
            dispatch(recruitersActions.viewAssessment(_id));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generatePastAssessmentTable = (_rows, owner) => {

        if (!_rows || Object.keys(_rows).length === 0) {
            return null;
        }

        return (
            <div className="table-responsive">
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Resume/CV File</th>
                            { owner === 'others' && <th scope="col">Submitted By</th> }
                            <th scope="col">Link</th>
                            <th scope="col">Report Status</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>

                        {Object.keys(_rows).map((key, idx) => {

                            const row = _rows[key];
                            const id = row._id || row.id;
                            const review_state = (assessments[id] && assessments[id].review_status) || (row && row.review_status);
                            let date = (assessments[id] && assessments[id].createdAt) || row.createdAt || 'N/A';

                            if (date !== 'N/A') {
                                date = new Date(date).toDateString()
                            }

                            let display_state = '<span class="text-danger">Not Found</span>';

                            if (review_state) {
                                // eslint-disable-next-line
                                switch (review_state) {
                                    case 'in-review':
                                    case 'needs-review':
                                        display_state = '<span class="text-info">Processing</span>';
                                        break;
                                    case 'verified':
                                        display_state = '<span class="text-success">Available</span>';
                                }
                            }

                            return (
                                <tr key={owner + '-' + id}>
                                    <td className="align-middle">{row?.name || row?.resume?.original_name || 'N/A'}</td>
                                    { owner === 'others' && <td className="align-middle">{row?.recruiter_email || 'Unknown'}</td> }
                                    <td className="align-middle"><NavLink to={"/assessment/" + id}>{id}</NavLink></td>
                                    <td className="align-middle" dangerouslySetInnerHTML={{ __html: display_state}} />
                                    <td className="align-middle">{date}</td>
                                </tr>
                            );

                        })}

                    </tbody>
                </table>
            </div>
        );

    };

    return (
        <div>

            <h1 className="text-center">Your Past Candidate Assessments</h1>
            { generatePastAssessmentTable(past_local_reports, 'owned') }
            
            {(!past_local_reports || Object.keys(past_local_reports).length === 0) &&
                <div className="text-center mt-3">
                    <NavLink to="/recruiter" className="btn btn-primary" role="button">Create a New Report</NavLink>
                </div>
            }
            
            <h1 className="text-center mt-3">Past Assessments from your Colleagues</h1>
            { generatePastAssessmentTable(company_assessments, 'others') }
            { (!company_assessments || Object.keys(company_assessments).length === 0) && <h5 className="text-center mt-3">Your colleagues have not created any assessments yet.</h5> }

        </div>
    );

}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};
