import { Routes, Route, Navigate, useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { history, subdomain } from '_helpers';
import { Nav, PrivateRoute } from '_components';
import { Admin } from 'admin';

// routes
import { Assessment } from 'assessment';
import { Create } from 'create';
import { Improvement } from 'improvement';
import { Login } from 'login';
import { PastReports } from 'past-reports';
import { SearchRank } from 'search-rank';
import { Subscription } from 'subscription';
import { Faq } from 'faq';
import { Profile } from 'profile';
import { Resume } from 'resume';
import { Signup } from 'signup';

export { App };

function App() {

    // This will run one time after the component mounts
    useEffect(() => {

        const whiteLabelLogo = document.getElementById('white-label-logo');

        whiteLabelLogo && whiteLabelLogo.addEventListener('error', function(event) {
            document.getElementById('white-label-wrap').classList.add('d-none');
            document.getElementById('main-nav').classList.add('mb-5');
        });

        const onPageLoad = () => {
            document.getElementById('page-load-spinner').style.display = 'none';
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        }
        else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }

    }, []);

    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    let footerProductName = 'Verified Profiles'
    let additionalFooterInfo = null;

    if (window.location.host.includes('go.') || window.location.host.includes('talentrank.')) {
        footerProductName = 'Talent Rank';
        additionalFooterInfo = <><small> | <a href="/privacy.html" target="_blank">Privacy Policy</a></small><small> | <NavLink to="/faq">FAQs</NavLink></small></>;
    }

    return (
        <>
            <div className="app-container bg-light">
                <Nav />
                <div className="container pt-4 pb-4">
                    <Routes>
                        <Route path="/" element={!subdomain.includes('go') ? <Create /> : <SearchRank />} />
                        {
                            subdomain.includes('go') && 
                            <Route path="/faq" element={<Faq />} />
                        }
                        <Route
                            path="/profile"
                            element={
                                <PrivateRoute>
                                    <Profile />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/assessment/:slug" element={<Assessment />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/recruiter" element={<Create />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/past-reports" element={<PastReports />} />
                        <Route path="/recruiter/improvement/:id" element={<Improvement behalf={"recruiter"}/>} />
                        <Route path="/improvement/:id" element={<Improvement />} />
                        <Route path="/resume/:id" element={<Resume />} />
                        <Route path="/search" element={<SearchRank />} />
                        <Route path="/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </div>
            <div className="text-center pt-4 pb-4">
                <a href="/" id="footer-product-name">{footerProductName}</a> by <small><a href="https://web3platforms.com">Web3 Platforms Inc</a></small>{additionalFooterInfo}
            </div>
        </>
    );
}
