import { yupResolver } from '@hookform/resolvers/yup';
import { recruitersActions } from '_store';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

export { CandidateEmail };

function CandidateEmail({ report }) {

    const dispatch = useDispatch();

    // form validation rules
    const validationSchema = Yup.object().shape({
        candidateEmail: Yup.string().email('Invalid email').required('Candidate email is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [submitError, setSubmitError] = useState(false);
    const [isResending, setResending] = useState(false);

    const onSubmit = async ({candidateEmail}) => sendEmail(report._id, candidateEmail);

    const sendEmail = async (id, candidateEmail) => {
        setSubmitError(false);
        setResending(true);

        const result = await dispatch(recruitersActions.submitCandidateEmail({ id: id, candidate_email: candidateEmail }));

        if (result.meta.requestStatus !== 'fulfilled') {
            setSubmitError(true);
        }

        setResending(false);
        return result;
    }

    if (report && report?.review_status === 'needs-user-evidence') {
        return (
            <div className="text-center m-3">
                <h5 className="mt-3">An email has been sent to the candidate for collecting more information.</h5>
                <Button type="button" variant="success" disabled={isResending} className="m-2" onClick={ async () => sendEmail(report._id, report.candidate_email) }>
                    Resend the email
                    {isResending && <span className="spinner-border spinner-border-sm ms-2"></span>}
                </Button>

                {submitError &&
                    <div className="row">
                        <div className="mx-auto text-center mt-2">
                            <div className="alert alert-danger" role="alert">
                                We had a problem submitting this request.
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
    else {
        return (
            <>
                <form className="col-md-8 mx-auto text-center m-3" onSubmit={handleSubmit(onSubmit)}>
                    <input
                        name="candidateEmail"
                        className={`text-center form-control ${errors.candidateEmail ? 'is-invalid' : ''}`}
                        {...register('candidateEmail')}
                        placeholder="Candidate email address"
                        type="text"
                    />
                    <div className="invalid-feedback">{errors.candidateEmail?.message}</div>
                    <Button type="submit" variant="primary" disabled={isSubmitting} className="mt-3">
                        Request answers from the candidate
                        {isSubmitting && <span className="spinner-border spinner-border-sm ms-2"></span>}
                    </Button>

                    {submitError &&
                        <div className="row">
                            <div className="mx-auto text-center mt-2">
                                <div className="alert alert-danger" role="alert">
                                    We had a problem submitting this request.
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </>
        );
    }
}
