import { yupResolver } from '@hookform/resolvers/yup';
import { history } from '_helpers';
import { authActions, userActions } from '_store';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTitle } from '../_hooks';
import { Link } from "react-router-dom";

export { Signup };

function Signup() {

    useTitle('Verified Profiles | Sign Up');

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.users?.current);
    const userError = useSelector(x => x.users?.error);
    const authError = useSelector(x => x.auth?.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
        //confirmpassword: Yup.string().required('Confirm password is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        job_company_name: Yup.string().required('Company is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onSubmit({ email, password, confirmpassword, firstName, lastName, job_company_name, job_title = 'Recruiter' }) {

        const result = await dispatch(userActions.create({ 
            email, 
            password, 
            firstName, 
            lastName, 
            job_company_name, 
            job_title,
            source: 'web'
        }));

        if (result.meta.requestStatus === 'fulfilled') {
            await dispatch(authActions.login({ email, password }));
            await dispatch(userActions.setCurrent(result.payload));
            history.navigate('/');
        }
        else {

        }

        return result;

    }

    return (
        <div className="col-md-6 offset-md-3 my-5">
            <div className="card">
                <h4 className="card-header">Signup</h4>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input id="email" name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input id="password" name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        {/*<div className="form-group">
                            <label>Confirm Password</label>
                            <input name="confirmpassword" type="password" {...register('confirmpassword')} className={`form-control ${errors.confirmpassword ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                        </div>*/}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="firstName">First Name</label>
                            <input id="firstName" name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.firstName?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="lastName">Last Name</label>
                            <input id="lastName" name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.lastName?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="job_company_name">Company</label>
                            <input id="job_company_name" name="job_company_name" type="text" {...register('job_company_name')} className={`form-control ${errors.job_company_name ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.job_company_name?.message}</div>
                        </div>
                        <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="job_title">Role</label>
                            <select className="form-select" name="job_title" id="job_title">
                                <option value="Recruiter">Recruiting</option>
                                <option value="Software Engineer">Software Engineering</option>
                                <option value="Product Manager">Product Management</option>
                            </select>
                        </div>

                        <div className="text-end">
                            <button disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                Register
                            </button>
                            <div className="mt-3">
                                <h6>Already have an account?</h6>
                                <Link to='/login' className="btn btn-secondary">Sign in</Link>
                            </div>
                        </div>

                        {(authError || userError) &&
                            <div className="alert alert-danger mt-3 mb-0 text-center">{authError?.message || 'Could not sign up using that email.'}</div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}
