import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { history, subdomain } from '_helpers';
import { recruitersActions } from '_store';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTitle } from '../_hooks';

export { Create };

function Create() {

    const dispatch = useDispatch();

    useTitle('Verified Profiles | New Candidate Report');

    const validFileExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'pdf', 'doc', 'docx'];
    const isValidFileType = function (fileName) {
        return fileName && validFileExtensions.indexOf(fileName.split('.').pop()) > -1;
    };

    // form validation rules
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        role: Yup.string().required('Role is required'),
        cv: Yup.mixed()
                .required('Required')
                .test("selected", "Resume or CV is required",
                (value) => {
                    return !(!value || value?.length === 0);
                })
                .test("is-valid-type", "Not a valid file type",
                    value => isValidFileType(value[0] && value[0]?.name?.toLowerCase()))
                .test(
                    "fileSize",
                    "Only documents up to 2MB are permitted.",
                    files =>
                        !files || // Check if `files` is defined
                        files.length === 0 || // Check if `files` is not an empty list
                        Array.from(files).every(file => file.size <= 2_000_000
                )
            )
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [submitError, setSubmitError] = useState(false);
    const lastEmailUsed = Cookies.get('last-email-used');

    async function onSubmit({ email, role, cv }) {

        setSubmitError(false);

        const formData = new FormData();
        formData.append('file', cv[0]);
        formData.append('role', role);
        formData.append('recruiter_email', email);

        const result = await dispatch(recruitersActions.submitCandidate(formData));

        if (result.payload.ok) {

            const data = await result.payload.json();

            let past = Cookies.get('past-reports');
            if (!past) {
                past = [];
            }
            else {
                past = JSON.parse(past);
            }
            past.push({id: data._id, name: data?.resume?.original_name || data._id});
            Cookies.set('past-reports', JSON.stringify(past));
            Cookies.set('last-email-used', email);

            window.gtag('set', {'user_id': email});

            history.navigate('/assessment/' + data._id);

        }
        else {
            setSubmitError(true);
        }

        return result;

    }

    const roles = (() => {
        switch(subdomain) {
            case 'creators':
                return [{key: "creator", name:'Creator / Influencer'}];
            default:
                return [{key: "SWE", name: "Software Engineer"}, {key: "PM", name: "Product Manager"}]
        }
    })();

    return (
        <div>

            <div className="row">
                <div className="col text-center mb-4">
                    <h1>Assess Candidate</h1>
                    <p>{subdomain === 'creators' ?
                        'Generate an assessment using your creators\'s most recent information' :
                        'Generate a level assessment using your candidate\'s most recent resume or performance review'
                    }.</p>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row">
                    <div className="col-md-5 mx-auto mb-3">
                        <div className="mb-3">
                            <div className="col-12 mx-auto text-center">
                                <input
                                    name="email"
                                    placeholder="Company Email Address"
                                    type="text" {...register('email')} className={`text-center form-control ${errors.email ? 'is-invalid' : ''}`}
                                    defaultValue={lastEmailUsed}
                                />
                                <div className="invalid-feedback text-start">{errors.email?.message}</div>
                                <small className="text-muted">We will send you updates about this person to your email address</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3">

                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <h6>Candidate Info</h6>
                            <div className="input-group has-validation mb-3">
                                <label className="input-group-text" htmlFor="role">Role</label>
                                <select className={`form-select ${errors.role ? 'is-invalid' : ''}`} id="role" {...register('role')}>
                                    <option value="" key="">Choose...</option>
                                    {roles.map((element, idx) => <option key={'choose-' + idx} value={element.key}>{element.name}</option>)}
                                </select>
                                <div className="invalid-feedback">{errors.role?.message}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className="input-group has-validation custom-file-button mb-3">
                                <label className="input-group-text" htmlFor="cv">{subdomain === 'creators' ? 'Select Creator Info...': 'Select a Resume/CV'}</label>
                                <input
                                    type="file"
                                    className={`form-control ${errors.cv ? 'is-invalid' : ''}`}
                                    {...register('cv')}
                                    id="cv"
                                    aria-describedby="cv"
                                />

                                <div className="invalid-feedback">"{errors.cv?.message}"</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mx-auto text-end">
                        <button disabled={isSubmitting} className="btn btn-primary" type="submit">
                            Get Assessment
                            {isSubmitting && <span className="spinner-border spinner-border-sm ms-2"></span>}
                        </button>
                    </div>
                </div>

                {submitError &&
                <div className="row">
                    <div className="col-md-6 mx-auto text-center mt-2">
                        <div className="alert alert-danger" role="alert">
                            We had a problem creating this report.
                        </div>
                    </div>
                </div>
                }

            </form>

        </div>
    )
}
