import { recruitersActions } from '_store';
import { useEffect } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from '../_hooks';
import { CandidateEmail } from './CandidateEmail';
import { Document } from './Document';
import { Questions } from './Questions';

export { Improvement };

function Improvement({behalf}) {

    useTitle('Improvement');

    const dispatch = useDispatch();
    const { assessments, error } = useSelector(x => x.recruiters);
    const { id } = useParams();
    const report = assessments && assessments[id];

    useEffect(() => {
        dispatch(recruitersActions.viewAssessment(id));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (report && report?.review_status !== 'verified' && report?.review_status !== 'needs-user-evidence') {
        return (
            <div className="col-md-8 mx-auto text-center rounded p-4 mb-3" style={{backgroundColor: '#f0f0f0'}}>
                <h1>We are now re-assessing the profile</h1>
                <p className="mt-4">We'll verify the answers provided and documents uploaded. We'll then reassess the candidate using this new data and generate a new report with a revised level if appropriate.</p>
            </div>
        );
    }
    else if (report && (report?.review_status === 'verified' || report?.review_status === 'needs-user-evidence')) {
        return (
            <div>
                <h3>Improve this profile</h3>
                <p className="text-muted">
                    We need more information about this candidate:
                    <a className="ms-1" href={`/assessment/${report?._id}`}>{report?.candidate_name}</a>
                </p>
                <p>You have the option to either request this information directly from the candidate or contact the candidate directly and fill in this section on their behalf.</p>

                <div className="rounded p-4 mb-3" style={{backgroundColor: '#cce4fc'}}>
                    <div className="row align-items-center mt-1 mb-1">
                        <div className="col-md-1 text-center">
                            <RiInformationLine style={{color: '#044484'}} size={'4rem'}/>
                        </div>
                        <div className="col-md-11">
                            <p style={{color: '#044484'}}>It is preferred for candidates to upload relevant documents to provide additional context.</p>
                            <p className="mb-0" style={{color: '#044484'}}>This allows us to verify the authenticity of the claims being made and ensures that the documents are provided directly from their employers.</p>
                        </div>
                    </div>
                </div>

                {behalf === 'recruiter' &&
                    <>
                        <CandidateEmail report={report}/>
                        <p className="text-muted text-center mb-4">- or -</p>
                    </>
                }

                <div className="mb-4">
                    <Document report={report}/>
                </div>

                {report.question && <Questions id={report._id} question={report.question}/>}
            </div>
        );
    }
    else if (error) {
        return (
            <div className="text-center">
                <h1>That's not good...</h1>
                <div className="alert alert-danger" role="alert">
                    It seems this candidate report does not exist.
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="row">
                <div className="col-md-4 mx-auto text-center">
                    <div className="spinner-grow text-primary mb-5 mt-5" role="status"></div>
                </div>
            </div>
        );
    }
}
