import { recruitersActions } from '_store';
import { useRef, useState } from 'react';
import { Carousel, ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Question } from './Question';

export { Questions };

function Questions({ id, question }) {

    const dispatch = useDispatch();

    const itemCount = question.length;

    const carouselRef = useRef(null);
    const [submitError, setSubmitError] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [answers, setAnswers] = useState({});

    const isFirstItem = () => activeIndex === 0;
    const isLastItem = () => activeIndex === itemCount - 1;

    const onItemSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };
    const onPrev = () => carouselRef.current.prev();
    const onNext = (dimension, answer) => {
        setAnswers({
            ...answers,
            [dimension]: answer
        })

        carouselRef.current.next()
    };

    const onSubmit = async (dimension, answer) => {
        setSubmitError(false);

        const allAnswers = {
            ...answers,
            [dimension]: answer
        };
        setAnswers(allAnswers);

        const result = await dispatch(recruitersActions.submitAnswer({ id: id, answer: allAnswers }));

        if (result.meta.requestStatus !== 'fulfilled') {
            setSubmitError(true);
        }

        return result;
    };

    return (
        <>
            <h3>Alternatively: Answer questions about your previous roles</h3>

            <ProgressBar className="mt-3 mb-3" now={(activeIndex + 1) / itemCount * 100} label={`Question ${activeIndex + 1} of ${itemCount}`}/>

            <Carousel controls={false} indicators={false} interval={null} slide={false} ref={carouselRef} onSelect={onItemSelect}>
                {question.map(({name, question1, question2}) => (
                    <Carousel.Item key={name}>
                        <Question
                            dimension={name}
                            question={[question1, question2]}
                            prev={!isFirstItem() ? onPrev : undefined}
                            next={!isLastItem() ? onNext : undefined}
                            submit={isLastItem() ? onSubmit : undefined}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>

            {submitError &&
                <div className="row">
                    <div className="mx-auto text-center mt-2">
                        <div className="alert alert-danger" role="alert">
                            We had a problem submitting this request.
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
