import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history, fetchWrapper } from '_helpers';
import { pendingBoilerplate, fulfilledBoilerplate, rejectedBoilerplate } from './shared-reducers';

//////////////////
// create slice //
//////////////////

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

/////////////
// exports //
/////////////

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

////////////////////
// implementation //
////////////////////

function createInitialState() {
    return {
        error: null,
        accessToken: '',
        refreshToken: '',
    };
}

function createReducers() {

    // return {
    //     logout
    // };

    // function logout(state) {
    //     console.log(11111)
    //     state = null;
    //     history.navigate('/login');
    // }

}

function createExtraActions() {

    const backendUrl = `${process.env.REACT_APP_BACKEND_API_URL}`;

    return {
        logout: logout(),
        login: login()
    };   

    function logout() {

        return createAsyncThunk(
            `${name}/logout`,
            async () => {}
        );

    } 

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${backendUrl}/authentication`, { 
                strategy: 'local-email',
                email, 
                password 
            })
        );
    }

}

function createExtraReducers() {

    return {
        ...logout(),
        ...login()
    };

    function logout() {

        var { pending, fulfilled, rejected } = extraActions.logout;

        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                state.accessToken = null;
                state.refreshToken = null;
            }),
            [rejected]: rejectedBoilerplate()
        };

    }

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {

                const { authentication } = action.payload;

                state.accessToken = authentication.accessToken;
                state.refreshToken = authentication.refreshToken;

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from);

                if (window?.LogRocket) {
                    window?.LogRocket.identify(action.payload.user._id, {
                        name: action?.payload?.user?.firstName + ' ' + action?.payload?.user?.lastName,
                        email: action?.payload?.user?._clear?.email
                    });
                }

            }),
            [rejected]: rejectedBoilerplate()
        };
    }

}
