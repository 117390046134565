import { mapNotNull } from '_helpers';
import { userActions } from '_store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyButton } from '../_components';

export { PaymentModal };

function PaymentModal({ show = false, tellParent, subscriptionOptions, filters, totalAvailableResultsNum }) {

	const [ isOpen, setIsOpen ] = useState(false);
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.users?.current);

	useEffect(() => {
		setIsOpen(show);
	}, [show]);

	const closeModal = () => {
		setIsOpen(false);
		tellParent(false);
	};

	const activeFilters = filters() || {};
	delete activeFilters.$sort;
	const filterKeys = Object.keys(activeFilters);

	const makeUpperCase = (str) => {
		return str.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
	};

	const showFiltersToBuy = filterKeys.map((key, idx) => {

		const k = makeUpperCase(key.replaceAll('~', '').replaceAll('$', '').replace(/([a-z](?=[A-Z]))/g, '$1 '));
		let v = makeUpperCase(activeFilters[key] || 'All');

		if (k === 'Experience' && v !== 'All') {
			v += '+ years'
		}

	    return (
	    	<tr key={key}>
	    		<td style={{ fontSize: 14 }}>{ k }</td>
	    		<td className="small">{ v }</td>
	    	</tr>
	    )
	});

	const setCheckoutSession = ({ client_reference_id, stripe }) => {
		dispatch(userActions.storeCheckoutSession({
			client_reference_id,
			filters: activeFilters,
			stripe
		}));
		closeModal();
	}

	if (!authUser) {
    	return null;
    }

	return (

		<>
			<div
				className={"modal fade " + (isOpen && 'show')}
				data-keyboard="false"
				tabIndex="-1"
				{...(isOpen && {style: {display: 'block'}})}
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">

					<div className="modal-content">

						<div className="modal-header">
							<div className="position-relative text-center" style={{ width: '100%' }}>
								<h4 className="modal-title">You don't have access to this profile</h4>
                            	<button onClick={closeModal} style={{ position: 'absolute', right: 0, top: 0 }} type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
							</div>
						</div>

						<div className="modal-body">

							<div className="container-fluid">

								<div className="row mb-2">
									<div className="col-md-12">
										<p className="mb-4">Subscribe today and gain full access to our extensive rankings, including <span className="fw-bold">full names</span> and <span className="fw-bold">social profiles</span> for this search <span className="small">(~{totalAvailableResultsNum} people)</span>:</p>
										<div className="mb-0 d-flex table-responsive justify-content-center">
											<table className="table table-striped table-bordered align-middle w-auto rounded rounded-1 overflow-hidden">
												<tbody>
													{showFiltersToBuy}
												</tbody>
											</table>
										</div>
									</div>
								</div>

								<div className="row mb-4">
									<div className="col-md-12">
										<div className="d-flex flex-column align-items-top justify-content-center">
										{mapNotNull(
											[process.env.REACT_APP_TALENT_PLINK_PER_MONTH, process.env.REACT_APP_TALENT_PLINK_HALF_YEAR],
											paymentLinkId => subscriptionOptions[paymentLinkId]
										).map(({payment_link, pricing_details}, idx) => {

											const price = (pricing_details.amount_total / 100).toLocaleString("en-US", {style: "currency", currency: pricing_details.currency});
											let cta = '';

											if (idx > 0 && subscriptionOptions[idx - 1]) {
												const badPrice = subscriptionOptions[idx]?.pricing_details?.price.recurring?.interval_count * subscriptionOptions[idx-1]?.pricing_details?.amount_total;
												cta = 'Save ' + (100 - Math.round( (pricing_details?.amount_total / badPrice) * 100 )) + '%';
											}

											return (
												idx === 0 ?
													<div className="mb-3" key={payment_link.id}>
														<BuyButton
															email={authUser?._clear?.email}
															price={price}
															title={''}
															url={payment_link.url}
															recurring={pricing_details.price.recurring}
															onClick={(client_reference_id) => {
																setCheckoutSession({
																	client_reference_id,
																	stripe: {
																		payment_link_id: payment_link.id,
																		price_id: pricing_details.price.id,
																		product_id: pricing_details.price.product
																	}
																})
															}}
														/>
													</div>
													:
													<div key={payment_link.id}>
														<BuyButton
															email={authUser?._clear?.email}
															price={price}
															title={'or'}
															url={payment_link.url}
															recurring={pricing_details.price.recurring}
															save="Special Offer"
															cta={cta || 'Unlock & Save'}
															onClick={(client_reference_id) => {
																setCheckoutSession({
																	client_reference_id,
																	stripe: {
																		payment_link_id: payment_link.id,
																		price_id: pricing_details.price.id,
																		product_id: pricing_details.price.product
																	}
																})
															}}
														/>
													</div>
											);

										})}
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12">
										<p>Our platform is built with trust, safety and authenticity at its core. As part of this commitment, we restrict access to full profiles and search results to our subscribers only. This preserves the authenticity of our platform and ensures access is granted only to verified individuals.</p>
									</div>
								</div>

							</div>

						</div>

						<div className="modal-footer">
							<button type="button" className="btn btn-danger me-2" data-dismiss="modal" onClick={closeModal}>Cancel</button>
						</div>

					</div>

				</div>

			</div>

		{isOpen && <div className="modal-backdrop fade show"></div>}

		</>

	);

};