import { SWE_LEVELS, PM_LEVELS } from '../_helpers';
import { CandidateAssessment, CandidateEnhancement } from '../_components';

export { TechAssessment };

function TechAssessment({ report }) {

    const getCompanyLevelStructure = (role) => {
        switch (role) {
          case 'SWE':
            return SWE_LEVELS;
          case 'PM':
            return PM_LEVELS;
          // Add more cases for other roles if needed
          default:
            return SWE_LEVELS;
        }
    };

    return (
        <>
        <h5 className="mt-4">Role: <br/><small className="text-muted">{report?.role}</small></h5>
        <h5>Level: <br/><small className="text-muted">{report?.overall_level}</small></h5>
        <p className="mt-4 fw-bold">Based on the candidate's resume and our standardized leveling guidelines, here's the level assessment:</p>
        <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Standardized Level</th>
                            {Object.keys(getCompanyLevelStructure(report?.role)[report?.overall_level]).map((company, idx) => {
                                return (
                                    <th scope="col" key={'company-' + company}>{company}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{report?.overall_level}</td>
                            {Object.keys(getCompanyLevelStructure(report?.role)[report?.overall_level]).map((company, idx) => {
                                return (
                                    <td key={'level-' + company}>{getCompanyLevelStructure(report?.role)[report?.overall_level][company]}</td>
                                );
                            })}
                        </tr>
                    </tbody>
        </table>

        <CandidateAssessment assessment={report?.assessment} />

        <h5>Why not level above: <br/><small className="text-muted">{report?.why_not_level_above}</small></h5>
        <h5>Why not level below: <br/><small className="text-muted">{report?.why_not_level_below}</small></h5>
        
        <CandidateEnhancement enhancements={report?.enhancement} />

        {report?.speed &&
        <div id="candidate-speed">
            <h4 className="mt-5">Progression Speed Assessment</h4>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th scope="col">Progression</th>
                        <th scope="col">Rationale</th>
                        <th scope="col">Time to 1 promotion</th>
                        <th scope="col">Time to 2 promotions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td>{report.speed.progression_speed_assessment}</td>
                        <td>{report.speed.rationale}</td>
                        <td>{report.speed.anticipated_one_level_above}</td>
                        <td>{report.speed.anticipated_two_levels_above}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        }
        </>
    );

}