import { yupResolver } from '@hookform/resolvers/yup';
import { recruitersActions } from '_store';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

export { Feedback };

function Feedback({id, pastfeedback}) {

    const dispatch = useDispatch();

    const formOptions = { resolver: yupResolver(Yup.object().shape({
        feedback: Yup.string().required('Please enter some feedback'),
    })) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting, isSubmitSuccessful } = formState;

    async function onSubmit({ feedback }) {
        await dispatch(recruitersActions.submitFeedback({id, feedback}));
    }

    return (
        <div id="candidate-feedback" className = "d-print-none">
            <h4 className="mt-5">Feedback</h4>
            <h6 className="text-muted">What would you like to change about this report/add to it? What did you find useful about it?<br />What did the candidate and company/client say about the report?</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <textarea
                        {...register('feedback')}
                        className={`text-start form-control ${errors.feedback ? 'is-invalid' : ''}`}
                        rows="3"
                        disabled={isSubmitting || isSubmitSuccessful}
                        {...(pastfeedback && {disabled: true, defaultValue: pastfeedback})}
                    ></textarea>
                <div className="invalid-feedback text-start">{errors.feedback?.message}</div>
                </div>
                {!pastfeedback && <button type="submit" className="btn btn-primary" disabled={isSubmitting || isSubmitSuccessful}>Submit</button>}
                {isSubmitSuccessful && <span className="text-success ms-2">Thank You!</span>}
            </form>
        </div>
    );

}