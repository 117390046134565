import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { userActions } from '_store';

export { Admin };

function Admin() {

    const dispatch = useDispatch();
    const { users } = useSelector(x => x.users);
    const authUser = useSelector(x => x.users?.current);

    useEffect(() => {
        authUser && dispatch(userActions.getAll('admin'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>
            <h1>Hi {authUser?.name || authUser?.firstName}!</h1>
            <p>{authUser ? "You're logged in." : <NavLink to="/login">Not logged in.</NavLink>}</p>
            <p>This is a public route, that tries to make a secure api call.</p>
            <p>Only users with the admin permission should be able to view the list below.</p>
            <h3>All admin users from secure api end point:</h3>
            {users && users.length &&
                <ul>
                    {users.map(user =>
                        <li key={user._id}>{user.name} {user.firstName} {user.lastName}</li>
                    )}
                </ul>
            }
            {users && users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users && users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}
        </div>
    );
}
