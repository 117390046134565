import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../_hooks';
import { Link } from 'react-router-dom';
import { userActions } from '_store';
import { BuyButton } from '../_components';
import { getAllUrlParams, makeUpperCase } from '../_helpers';
import { CreditCard2Front } from 'react-bootstrap-icons';

export { Subscription };

function Subscription() {

    useTitle('Verified Profiles | Subscription');

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.users?.current);
    const loading = useSelector(x => x.users?.loading);
    const paidSearches = authUser?.activeTalentSubscriptionFilters;
    const [ subsState, setSubsState ] = useState([]);

    useEffect(() => {
        dispatch(userActions.getLatestInfo({_id: authUser._id}));
    }, [authUser._id, dispatch]);

    useEffect(() => {

        const query = paidSearches?.map((filterKeys, idx) => {
            return '$or[' + idx + '][referenceId]=' + filterKeys?.__stripe?.client_reference_id + '&';
        }).join('');

        dispatch(userActions.getPaymentStatus({list_of_ids: query})).then((result) => {

            let mappings = {};

            if (result?.payload?.total > 0) {

                result.payload.data.forEach((data) => {
                    mappings[data.referenceId] = data?.stripeData?.subscription_status?.toUpperCase();
                });

                setSubsState(mappings);
            }
        });

    }, [paidSearches, dispatch]);

    const buildQueryUrl = (filterKeys) => {
        let filterString = '';
        Object.keys(filterKeys).map((key, idx) => {
            if (key !== '_id' && key !== '__stripe') {
                filterString += key + '=' + filterKeys[key] + '&';
            }
            return null;
        });
        return filterString;
    }

    // redirect if we got here from the checkout
    const pageUrlParams = getAllUrlParams();
    let client_reference_id_loading = false;
    if (pageUrlParams?.client_reference_id) {
        client_reference_id_loading = true;
        paidSearches?.map((filterKeys, idx) => {
            if (filterKeys?.__stripe?.client_reference_id === pageUrlParams?.client_reference_id) {
                client_reference_id_loading = false;
                window.location.href = '/search?_success=1&' + buildQueryUrl(filterKeys);
            }
            return null;
        });
        client_reference_id_loading = false;
    }

    if (pageUrlParams?.client_reference_id && client_reference_id_loading) {
        return (
            <div className="col-md-12 text-center">

                <div className="mt-5">
                    <span className="spinner-grow text-warning mb-2" style={{ height: 100, width: 100 }}></span>
                    <div className="text-warning">Please wait...</div>
                </div>

            </div>
        );
    }


    return (
        <div className="col-md-12">

            <div className="mt-3 mb-5 text-center">
                <h1 className="text-center">Paid Searches</h1>
                {!loading && paidSearches?.length > 0 &&
                <a role="button" className="btn text-muted" href={process.env.REACT_APP_MANAGE_SUBS_URL + 'prefilled_email=' + (authUser?._clear?.email || '')}>
                    <CreditCard2Front style={{ marginTop: -3 }}/> Manage Subscription
                </a>
                }
            </div>

            {loading && <div className="text-center mb-5">
                <span className="spinner-border spinner-border-md"></span>
                <div>Fetching your paid searches...</div>
            </div>}
            {!loading && paidSearches?.length > 0 ? <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Job Title</th>
                            <th scope="col">Gender</th>
                            <th scope="col">City</th>
                            <th scope="col">Experience</th>
                            <th scope="col">Speciality</th>
                            <th scope="col" className="text-center">Search Results</th>
                            <th scope="col" className="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paidSearches?.map((filterKeys, idx) => {

                                const filterString = buildQueryUrl(filterKeys);

                                return (
                                    <tr key={'sub-' + idx}>
                                        <td className="align-middle">{ makeUpperCase(filterKeys?.jobTitle || '-')}</td>
                                        <td className="align-middle">{ makeUpperCase(filterKeys?.gender || '-')}</td>
                                        <td className="align-middle">{ makeUpperCase(filterKeys?.city || '-')}</td>
                                        <td className="align-middle">{ makeUpperCase(filterKeys?.$experience || '-')}</td>
                                        <td className="align-middle">{ makeUpperCase(filterKeys['~speciality'] || '-')}</td>
                                        <td className="text-center align-middle">
                                            <Link to={'/search?' + filterString} className="btn btn-primary">View</Link>
                                        </td>
                                        <td className="align-middle text-center">
                                            {subsState[filterKeys?.__stripe?.client_reference_id] || <div className="spinner-border text-primary" role="status" />}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            :
            <div>
                {!loading && 
                    <>
                    <h3 className="text-center text-muted mt-0 mb-5">You don't have any paid searches yet.</h3>
                    <div className="d-none justify-content-center flex-wrap">
                        <div className="me-2"><BuyButton bid="buy_btn_1NUOxbDeF3AHR86eYwNI2Id9" /></div>
                        <div><BuyButton bid="buy_btn_1NUU1kDeF3AHR86eHnZg8cKo" /></div>
                    </div>
                    </>
                }
            </div>
            }
        </div>
    );

}
