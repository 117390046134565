export const SWE_LEVELS = {
  L3: {
    Google: 'L3 SWE III',
    Facebook: 'E3',
    Microsoft: '59 SDE',
    Amazon: 'L4 SDE I',
    Tencent: 'T4',
    Bytedance: '1-2',
    TSMC: '31',
    Apple: 'ICT2 SWE',
  },
  L4: {
    Google: 'L4 SWE III',
    Facebook: 'E4',
    Microsoft: '61 SDE II',
    Amazon: 'L5 SDE II',
    Tencent: 'T7',
    Bytedance: '2-1',
    TSMC: '32',
    Apple: 'ICT3 SWE',
  },
  L5: {
    Google: 'L5 Senior SWE',
    Facebook: 'E5',
    Microsoft: '63 Senior SDE',
    Amazon: 'L6 SDE III',
    Tencent: 'T10',
    Bytedance: '2-2',
    TSMC: '33',
    Apple: 'ICT4 Senior SWE',
  },
  L6: {
    Google: 'L6 Staff SWE',
    Facebook: 'E6',
    Microsoft: '65 Principal SDE',
    Amazon: 'L6 SDE III',
    Tencent: 'T13',
    Bytedance: '3-1',
    TSMC: '35',
    Apple: 'ICT5',
  },
  L7: {
    Google: 'L7 Senior Staff SWE',
    Facebook: 'E7',
    Microsoft: '67',
    Amazon: 'L7 Principal SDE',
    Tencent: 'T15',
    Bytedance: '3-2',
    TSMC: '36',
    Apple: 'ICT6',
  },
  L8: {
    Google: 'L8 Principal Engineer',
    Facebook: 'E8',
    Microsoft: '68 Partner',
    Amazon: 'L8 Senior Principal SDE',
    Tencent: 'T16',
    Bytedance: '4-1',
    TSMC: '36',
    Apple: 'ICT6',
  },
};