import { CandidateCoachingPlan } from '_components/CandidateCoachingPlan';
import { CandidateAssessment, CandidateEnhancement } from '../_components';

export { CreatorAssessment };

function CreatorAssessment({ report }) {

    return (
        <>
            <h5 className="mt-4">Role: <br/><small className="text-muted" style={{textTransform: 'capitalize'}}>{report?.role}</small></h5>
            <h5>Level: <br/><small className="text-muted">{report?.overall_level}</small></h5>

            <h5>Explain if this creator is suited for a brand's influencer marketing campaign: <br/><small className="text-muted">{report?.creator?.explain_if_suited}</small></h5>
            <h5>Potential Number Of Views: <br/><small className="text-muted">{report?.creator?.potential_number_of_views}</small></h5>
            <h5>Estimated Conversion Rate: <br/><small className="text-muted">{report?.creator?.estimated_conversion_rate}</small></h5>

            {report?.creator?.marketing_campaigns &&
            <>
            <h5>Marketing Campaigns:</h5>
            <ul style={{ listStyleType: 'none' }}>
            {report?.creator?.marketing_campaigns.split(/\s*(?=\d+\.\s)/).map(
                ele => (<li key={ele} className="text-muted">{ele}</li>)
            )}
            </ul>
            </>
            }

            <h5>Suggested Pricing: <br/><small className="text-muted">{report?.creator?.suggested_pricing}</small></h5>

            <CandidateAssessment assessment={report?.assessment} />

            <h5>Why not level above: <br/><small className="text-muted">{report?.why_not_level_above}</small></h5>
            <h5>Why not level below: <br/><small className="text-muted">{report?.why_not_level_below}</small></h5>

            <CandidateEnhancement enhancements={report?.enhancement} />

            <CandidateCoachingPlan plans={report?.creator?.coaching_plan} />

        </>

    );

}
