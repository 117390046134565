import { history } from '_helpers';
import { recruitersActions } from '_store';
import html2pdf from 'html2pdf.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Feedback } from '../_components';
import { useTitle } from '../_hooks';
import { CreatorAssessment } from './CreatorAssessment';
import { TechAssessment } from './TechAssessment';

export { Assessment };

function Assessment() {

    useTitle('Verified Profiles | View Assessment');

    const dispatch = useDispatch();
    const { assessments, error } = useSelector(x => x.recruiters);
    const { slug } = useParams();
    const navigate = useNavigate();
    const report = assessments && assessments[slug];

    useEffect(() => {

        dispatch(recruitersActions.viewAssessment(slug));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const savePdf = (from) => {

        const opt = {
            filename: report.candidate_name + ' Web3 Platforms Assessment.pdf',
            margin: 10,
        };

        html2pdf().from(document.getElementById(from)).set(opt).toPdf().get('pdf').then(function(pdf) {

            const totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                //Add you content in place of example here
                pdf.text(report.candidate_name + ' - Web3 Platforms Inc.', 10, 10);
            }

        }).save();

    };

    const RoleBasedComponent = () => {

        switch (report?.role) {
          case 'creator':
            return <CreatorAssessment report={report} />;
          default:
            return <TechAssessment report={report} />;
        }
    };

    if (report && report?.review_status !== 'verified' && report?.review_status !== 'needs-user-evidence') {
        return (
            <div className="text-center">
                <h1>Hang in there!</h1>
                <h5 className="mt-3">Our system is hard at work assessing your candidate's profile.</h5>
                <h5>You are using the free version of our product and can expect the process to be completed within <i>12 hours</i>.</h5>
                <h5 className="mt-3 mb-3 text-muted">We will send you an email when this is ready, or you can refresh this page too.</h5>
                <button className="btn btn-primary mt-2" type="button" onClick={()=>  history.navigate('/')}>Create another report</button>
            </div>
        );
    }
    else if (report && (report?.review_status === 'verified' || report?.review_status === 'needs-user-evidence')) {
        const level = Number(report.overall_level.slice(-1));
        return (
            <div>
                <div className="d-print-none rounded p-4 mb-2" style={{backgroundColor: '#f0f0f0'}}>
                    <h3>Quickly improve this profile from <span className="fw-bold">L{level}</span> to <span className="fw-bold">L{level + 1}</span></h3>
                    <p className="mt-3">
                        The candidate is a <span className="fw-bold">Level {level}</span> {report.role}. Given the right experience, they could be suitable for a <span className="fw-bold">Level {level + 1}</span> role. Their resume might not showcase their full potential. Upon receiving further information from the candidate, we'll reevaluate and update their level accordingly.
                    </p>
                    <button className="btn btn-primary mt-3" type="button" onClick={()=> navigate('/recruiter/improvement/' + report._id)}>Provide more information</button>
                </div>

                <h1>{report?.candidate_name}</h1>
                <h5>Recruiter Email: <small className="text-muted">{report?.recruiter_email}</small></h5>

                <div className="d-print-none">
                    <button type="button" className="btn btn-success me-1 mb-1" onClick={() => savePdf('candidate-assessment')}>Download Assessment (PDF)</button>
                    <br className="d-md-none" />
                    <button type="button" className="btn btn-success me-1 mb-1" onClick={() => savePdf('candidate-enhancements')}>Download Enhancements (PDF)</button>
                    <br className="d-md-none" />
                    <button className="btn btn-primary mb-1" onClick={window.print}>Print</button>
                </div>
                <RoleBasedComponent/>
                <Feedback id={report._id} pastfeedback={report?.feedback} />
            </div>
        );
    }
    else if (report && report?.review_status === 'fake') {
        return (
            <div className="text-center">
                <h1>Document Authenticity Alert</h1>
                <div className="alert alert-danger" role="alert">
                    A profile for this candidate could not be generated due to the detection of a potentially fraudulent, misleading, or inauthentic document. Please exercise caution when reviewing the candidate's claims. For any queries or concerns, reach out to our Global Security and Intelligence team at help@web3platforms.com.
                </div>
            </div>
        );
    }
    else if (error) {
        return (
            <div className="text-center">
                <h1>That's not good...</h1>
                <div className="alert alert-danger" role="alert">
                    It seems this candidate report does not exist.
                </div>
            </div>
        );
    }
    else {
        return (
        <div className="row">
            <div className="col-md-4 mx-auto text-center">
                <div className="spinner-grow text-primary mb-5 mt-5" style={{width: '8rem', height: '8rem'}} role="status"></div>
            </div>
        </div>
        );
    }

}
