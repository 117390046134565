import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { pendingBoilerplate, fulfilledBoilerplate, rejectedBoilerplate } from './shared-reducers';
import { fetchWrapper } from '_helpers';

//////////////////
// create slice //
//////////////////

const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

/////////////
// exports //
/////////////

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

////////////////////
// implementation //
////////////////////

function createInitialState() {
    return {
        error: null,
        current: null
    };
}

function createExtraActions() {

    const baseUrl = `${process.env.REACT_APP_BACKEND_API_URL}`;

    return {
        getAll: getAll(),
        create: create(),
        setCurrent: setCurrent(),
        getLatestInfo: getLatestInfo(),
        setIsAdmin: setIsAdmin(),
        storeCheckoutSession: storeCheckoutSession(),
        getPaymentStatus: getPaymentStatus()
    };

    function create(data) {
            return createAsyncThunk(
            `${name}/create`,
            async (data) => await fetchWrapper.post(baseUrl + '/users', data)
        );
    }

    function getAll(permFilter) {

        return createAsyncThunk(
            `${name}/getAll`,
            async (permFilter) => await fetchWrapper.get(baseUrl + '/users' + (permFilter ? '?permissions[$in]=' + permFilter : ''))
        );

    }

    function getLatestInfo() {
        return createAsyncThunk(
            `${name}/getLatestInfo`,
            async ({_id}) => await fetchWrapper.get(baseUrl + '/users/' + _id)
        );
    }

    function setCurrent(data) {
        return createAsyncThunk(
            `${name}/setCurrent`,
            (data) => {
                return data
            }
        );
    }

    function setIsAdmin() {
        return createAsyncThunk(
            `${name}/setIsAdmin`,
            (newValue) => newValue
        );
    }

    function storeCheckoutSession() {
            return createAsyncThunk(
            `${name}/storeCheckoutSession`,
            async (data) => await fetchWrapper.post(baseUrl + '/payment', {
                type: 'checkout-session',
                referenceId: data.client_reference_id,
                userOptions: { filters: data.filters },
                stripeData: data.stripe
            })
        );
    }

    function getPaymentStatus() {
        return createAsyncThunk(
            `${name}/getPaymentStatus`,
            async ({ list_of_ids }) => await fetchWrapper.get(baseUrl + '/payment/?' + list_of_ids)
        );
    }

}

function createExtraReducers() {

    return {
        ...create(),
        ...getAll(),
        ...setCurrent(),
        ...getLatestInfo(),
        ...setIsAdmin(),
    };

    function create() {
        var { pending, fulfilled, rejected } = extraActions.create;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate(),
            [rejected]: rejectedBoilerplate()
        };
    }

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                state.users = action.payload.data;
            }, name),
            [rejected]: rejectedBoilerplate()
        };
    }

    function updateCurrent(name) {
        var { pending, fulfilled, rejected } = extraActions[name];
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                state.current = action.payload;
            }, 'current'),
            [rejected]: rejectedBoilerplate()
        };
    }

    function setCurrent() {
        return updateCurrent('setCurrent');
    }

    function getLatestInfo() {
        return updateCurrent('getLatestInfo');
    }

    function setIsAdmin() {
        var { pending, fulfilled, rejected } = extraActions.setIsAdmin;
        return {
            [pending]: pendingBoilerplate(),
            [fulfilled]: fulfilledBoilerplate((state, action) => {
                let perms = state?.current?.permissions || [];
                if (action.payload &&  !state?.current?.permissions.includes('admin')) {
                    perms.push('admin')
                }
                else {
                    perms = perms.filter(v => v !== 'admin')
                }
                state.current.permissions = perms;
            }, 'current'),
            [rejected]: rejectedBoilerplate()
        };
    }

}
