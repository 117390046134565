import { yupResolver } from '@hookform/resolvers/yup';
import { recruitersActions } from '_store';
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

export { Document };

function Document({ report }) {

    const dispatch = useDispatch();

    const validFileExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'pdf', 'doc', 'docx'];
    const isValidFileType = (fileName) => {
        return fileName && validFileExtensions.indexOf(fileName.split('.').pop()) > -1;
    };

    const isValidTypeSchema = Yup.mixed().test(
        "is-valid-type",
        "Not a valid file type",
        files =>
            !files || // Check if `files` is defined
            files.length === 0 || // Check if `files` is not an empty list
            isValidFileType(files[0] && files[0]?.name?.toLowerCase())
    );

    const fileSizeSchema = Yup.mixed().test(
        "file-size",
        "Only documents less than 2MB are permitted.",
        files =>
            !files || // Check if `files` is defined
            files.length === 0 || // Check if `files` is not an empty list
            Array.from(files).every(file => file.size <= 2_000_000)
    );

    // form validation rules
    const validationSchema = Yup.object().shape({
        doc1: Yup.mixed()
            .test(
                "selected",
                "Upload at least one document",
                function(doc1) {
                    const { doc2, doc3 } = this.parent;
                    return [doc1, doc2, doc3].some(files => files && files.length !== 0);
                }
            )
            .concat(isValidTypeSchema)
            .concat(fileSizeSchema),
        doc2: isValidTypeSchema.concat(fileSizeSchema),
        doc3: isValidTypeSchema.concat(fileSizeSchema),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [submitError, setSubmitError] = useState(false);

    const onSubmit = async ({doc1, doc2, doc3}) => {
        setSubmitError(false);

        const formData = new FormData();
        formData.append('file', doc1?.length > 0 ? doc1[0] : undefined);
        formData.append('file', doc2?.length > 0 ? doc2[0] : undefined);
        formData.append('file', doc3?.length > 0 ? doc3[0] : undefined);

        const result = await dispatch(recruitersActions.submitDocument({ id: report._id, formData: formData }));

        if (result.meta.requestStatus !== 'fulfilled') {
            setSubmitError(true);
        }

        return result;
    }

    return (
        <>
            <h3>Upload candidate documents</h3>
            <p className="text-muted">Upload documents that provide additional context</p>
            <p>Please upload documents that effectively demonstrate your scope and impact, your ability to handle oversight and ambiguity, your leadership skills, your proficiency in coordination and process improvement, as well as your broader impact and hiring contributions.</p>
            <hr/>

            <div className="rounded p-4" style={{backgroundColor: '#f0f0f0'}}>
                <p>The most valuable document would be your <span className="fw-bold">full performance review</span> or <span className="fw-bold">appraisal</span>, including peer and manager feedback. Letters of recommendation would also be valuable. You can upload as many documents as you need.</p>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    {['doc1', 'doc2', 'doc3'].map((doc, idx) => (
                        <InputGroup className='has-validation mb-2' key={idx}>
                            <Form.Control
                                type='file'
                                {...register(doc)}
                                id={doc}
                                isInvalid={!!errors[doc]}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors[doc]?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    ))}

                    <div className="text-end mt-1">
                        <Button type="submit" variant="success" disabled={isSubmitting}>
                            Submit
                            {isSubmitting && <span className="spinner-border spinner-border-sm ms-2"></span>}
                        </Button>
                    </div>

                    {submitError &&
                        <div className="row">
                            <div className="mx-auto text-center mt-2">
                                <div className="alert alert-danger" role="alert">
                                    We had a problem submitting this request.
                                </div>
                            </div>
                        </div>
                    }
                </Form>

            </div>
        </>
    );
}
